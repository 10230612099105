import {OPENSEA_API_URL, OPENSEA_API_KEY} from '../../../config';

/**
 * getNFTAssets
 *
 * Fetches NFTs by contractAddresses and tokenIds using the OpenSea API.
 *
 * @param {string[]} contractAddresses
 * @param {string[]} tokenIds
 * @returns {Promise<Record<string, string>[]>}
 */
export async function getNFTAssets(
  contractAddresses: string[],
  tokenIds: string[]
): Promise<Record<string, string>[]> {
  let fetchedGroupSize = 0;
  let fetchedNFTs: any = [];
  let options = {method: 'GET'};
  if (OPENSEA_API_KEY) {
    options['headers'] = {'X-API-KEY': OPENSEA_API_KEY};
  }

  while (contractAddresses.length) {
    const contractAddressesChunk = contractAddresses.splice(0, 20);
    const tokenIdsChunk = tokenIds.splice(0, 20);

    const contractAddressesParam = contractAddressesChunk
      .map((address) => `asset_contract_addresses=${address}`)
      .join('&');
    const tokenIdsParam = tokenIdsChunk
      .map((id) => `token_ids=${id}`)
      .join('&');

    try {
      const response = await fetch(
        `${OPENSEA_API_URL}/assets?${contractAddressesParam}&${tokenIdsParam}&limit=20`,
        options
      );
      const {assets} = await response.json();
      fetchedNFTs = [...fetchedNFTs, ...assets];
      fetchedGroupSize = assets.length;
      if (fetchedGroupSize === 20) {
        // Wait between requests to mitigate API rate limiting.
        new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 1000);
        });
      }
    } catch (error) {
      throw new Error('Failed to fetch NFT JSON data from API');
    }
  }

  return fetchedNFTs;
}
