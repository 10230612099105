import {Route, Switch} from 'react-router-dom';

// import AdapterOrExtensionManager from './components/adapters-extensions/AdapterOrExtensionManager';
import CreateGovernanceProposal from './pages/governance/CreateGovernanceProposal';
import CreateNFTTributeProposal from './pages/nfts/proposals/CreateNFTTributeProposal';
import GetStarted from './pages/start/GetStarted';
import GovernanceProposalDetails from './pages/governance/GovernanceProposalDetails';
import GovernanceProposals from './pages/governance/GovernanceProposals';
import MemberProfile from './pages/members/MemberProfile';
import Members from './pages/members/Members';
import NFTs from './pages/nfts/NFTs';
import NFTTributeDetails from './pages/nfts/proposals/NFTTributeDetails';
import NFTTributes from './pages/nfts/proposals/NFTTributes';
import NotFound from './pages/subpages/NotFound';
import Redeem from './pages/redeem/Redeem';

const proposalIdParameter: string = ':proposalId';

export default function Routes() {
  return (
    <Switch>
      {[
        // Index page
        <Route key="splash" exact path="/" render={() => <GetStarted />} />,
        <Route
          key="donate"
          exact
          path="/donate"
          render={() => <CreateNFTTributeProposal />}
        />,
        <Route
          key="curation"
          exact
          path="/curation"
          render={() => <NFTTributes />}
        />,
        <Route
          key="curation-details"
          exact
          path={`/curation/${proposalIdParameter}`}
          render={() => <NFTTributeDetails />}
        />,
        <Route
          key="governance-proposal"
          exact
          path="/governance-proposal"
          render={() => <CreateGovernanceProposal />}
        />,
        <Route
          key="governance-proposals"
          exact
          path="/governance"
          render={() => <GovernanceProposals />}
        />,
        <Route
          key="governance-proposal-details"
          exact
          path={`/governance/${proposalIdParameter}`}
          render={() => <GovernanceProposalDetails />}
        />,
        <Route
          key="members"
          exact
          path="/members"
          render={() => <Members />}
        />,
        <Route
          key="member-profile"
          exact
          path="/members/:ethereumAddress"
          render={() => <MemberProfile />}
        />,
        // @note Disabling DAO Manager for now because we paused on
        // maintaining it.
        // <Route
        //   key="dao-manager"
        //   exact
        //   path="/dao-manager"
        //   render={() => <AdapterOrExtensionManager />}
        // />,
        <Route key="nfts" exact path="/collection" render={() => <NFTs />} />,
        <Route key="redeem" exact path="/redeem" render={() => <Redeem />} />,
        // 404 component (note: does not redirect to a route to maintain original path)
        <Route key="no-match" component={NotFound} />,
      ]}
    </Switch>
  );
}
