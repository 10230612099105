import {useState, useEffect, useCallback} from 'react';
import {Link} from 'react-router-dom';

import {
  useNFTCollection,
  useNFTAssets,
  useNFTAssetsByOwner,
} from '../../components/nfts/hooks';
import {FetchedNFT} from '../../components/nfts/types';
import NFTCard from '../../components/nfts/NFTCard';
import Wrap from '../../components/common/Wrap';
import FadeIn from '../../components/common/FadeIn';
import Muse0Text from '../../components/common/Muse0Text';
import {AsyncStatus} from '../../util/types';
import LoaderLarge from '../../components/feedback/LoaderLarge';
import ErrorMessageWithDetails from '../../components/common/ErrorMessageWithDetails';
import {useQueryParams} from '../../hooks';
import NFTDetailsModal from './NFTDetailsModal';
import {DAO_NFT_ADDITIONAL_OWNER} from '../../config';

export default function NFTs() {
  /**
   * State
   */

  const [showNFTDetailsModal, setShowNFTDetailsModal] =
    useState<boolean>(false);
  const [allNFTs, setAllNFTs] = useState<FetchedNFT[]>([]);

  /**
   * Our hooks
   */

  const {collectedNFTs, collectedNFTsError, collectedNFTsStatus} =
    useNFTCollection();
  const {nfts, nftsError, nftsStatus} = useNFTAssets(collectedNFTs);
  const {ownedNFTs, ownedNFTsError, ownedNFTsStatus} = useNFTAssetsByOwner(
    DAO_NFT_ADDITIONAL_OWNER
  );
  const query = useQueryParams();

  /**
   * Cached callbacks
   */

  const getAllNFTsCached = useCallback(getAllNFTs, [nfts, ownedNFTs]);

  /**
   * Variables
   */

  const isLoading: boolean =
    collectedNFTsStatus === AsyncStatus.STANDBY ||
    collectedNFTsStatus === AsyncStatus.PENDING ||
    (nftsStatus === AsyncStatus.STANDBY && collectedNFTs.length > 0) ||
    nftsStatus === AsyncStatus.PENDING ||
    (DAO_NFT_ADDITIONAL_OWNER && ownedNFTsStatus === AsyncStatus.STANDBY) ||
    ownedNFTsStatus === AsyncStatus.PENDING;
  const error: Error | undefined =
    collectedNFTsError || nftsError || ownedNFTsError;
  const tokenAddress = query.get('address');
  const tokenId = query.get('tokenId');

  /**
   * Effects
   */

  useEffect(() => {
    getAllNFTsCached();
  }, [getAllNFTsCached]);

  // show NFTDetailsModal if `address` and `tokenId` query params exist
  useEffect(() => {
    if (tokenAddress && tokenId) {
      setShowNFTDetailsModal(true);
    } else {
      setShowNFTDetailsModal(false);
    }
  }, [tokenAddress, tokenId]);

  /**
   * Functions
   */

  // Consolidate NFTs that have been transferred to (1) the NFT extension
  // contract (ERC721 token types) and (2) the `DAO_NFT_ADDITIONAL_OWNER`
  // (non-ERC721 token types)
  function getAllNFTs() {
    const allNFTsSorted = [...nfts, ...ownedNFTs]
      // Sort by NFT contract name (asc) then contract address (asc) then
      // tokenId (asc).
      .sort(
        (a, b) =>
          a.tokenName.localeCompare(b.tokenName) ||
          a.address.localeCompare(b.address) ||
          Number(a.tokenId) - Number(b.tokenId)
      );

    setAllNFTs(allNFTsSorted);
  }

  function renderNFTCards(): React.ReactNode {
    return allNFTs.map((token) => {
      const {address, tokenId} = token;

      return (
        <Link
          key={`${address}/${tokenId}`}
          to={`/collection?address=${address}&tokenId=${tokenId}`}
          style={{textDecoration: 'none'}}>
          <NFTCard {...token} />
        </Link>
      );
    });
  }

  /**
   * Render
   */

  // Render loading
  if (isLoading && !error) {
    return (
      <RenderWrapper>
        <div className="loader--large-container">
          <LoaderLarge />
        </div>
      </RenderWrapper>
    );
  }

  // Render no NFTs
  if (
    collectedNFTsStatus === AsyncStatus.FULFILLED &&
    (!DAO_NFT_ADDITIONAL_OWNER || ownedNFTsStatus === AsyncStatus.FULFILLED) &&
    !allNFTs.length
  ) {
    return (
      <RenderWrapper>
        <p className="text-center">No NFTs, yet!</p>
      </RenderWrapper>
    );
  }

  // Render error
  if (error) {
    return (
      <RenderWrapper>
        <div className="text-center">
          <ErrorMessageWithDetails
            error={error}
            renderText="Something went wrong while getting the NFTs."
          />
        </div>
      </RenderWrapper>
    );
  }

  // Render NFTs
  return (
    <RenderWrapper>
      <>
        <div className="grid--fluid grid-container">
          <div className="grid__cards">{renderNFTCards()}</div>
        </div>
      </>

      {showNFTDetailsModal && (
        <NFTDetailsModal
          isOpen={showNFTDetailsModal}
          nfts={allNFTs}
          tokenAddress={tokenAddress || undefined}
          tokenId={tokenId || undefined}
        />
      )}
    </RenderWrapper>
  );
}

function RenderWrapper(props: React.PropsWithChildren<any>): JSX.Element {
  /**
   * Render
   */

  return (
    <Wrap className="section-wrapper">
      <FadeIn>
        <div className="titlebar">
          <div className="titlebar__title-container">
            <h2 className="titlebar__title">Collection</h2>
            <div className="titlebar__subtitle">
              <p>
                Below are works selected by members for <Muse0Text />
                &apos;s permanent collection.
              </p>
            </div>
          </div>
        </div>

        {/* RENDER CHILDREN */}
        {props.children}
      </FadeIn>
    </Wrap>
  );
}
