import {useNFTAssetsByOwner} from '../../components/nfts/hooks';
import {FetchedNFT} from '../../components/nfts/types';
import NFTCard from '../../components/nfts/NFTCard';
import Modal from '../common/Modal';
import {AsyncStatus} from '../../util/types';
import LoaderLarge from '../../components/feedback/LoaderLarge';
import ErrorMessageWithDetails from '../../components/common/ErrorMessageWithDetails';
import TimesSVG from '../../assets/svg/TimesSVG';

type OwnedNFTsModalProps = {
  isOpen: boolean;
  closeHandler: () => void;
  selectNFTHandler: (nft: FetchedNFT) => void;
  owner?: string;
};

export default function OwnedNFTsModal({
  isOpen,
  closeHandler,
  selectNFTHandler,
  owner,
}: OwnedNFTsModalProps): JSX.Element {
  /**
   * Our hooks
   */

  const {ownedNFTs, ownedNFTsError, ownedNFTsStatus} =
    useNFTAssetsByOwner(owner);

  /**
   * Functions
   */

  function renderNFTCards(ownedNFTs: FetchedNFT[]): React.ReactNode {
    return ownedNFTs.map((nft) => {
      const {address, tokenId} = nft;

      return (
        <NFTCard
          key={`${address}/${tokenId}`}
          showInModal={true}
          {...nft}
          selectNFTHandler={selectNFTHandler}
          closeHandler={closeHandler}
        />
      );
    });
  }

  function renderModalContent() {
    // Render loading
    if (
      ownedNFTsStatus === AsyncStatus.STANDBY ||
      ownedNFTsStatus === AsyncStatus.PENDING
    ) {
      return (
        <div className="loader--large-container">
          <LoaderLarge />
        </div>
      );
    }

    // Render no NFTs
    if (!ownedNFTs.length && ownedNFTsStatus === AsyncStatus.FULFILLED) {
      return (
        <p className="text-center">
          Your connected account does not own any NFTs.
        </p>
      );
    }

    // Render error
    if (ownedNFTsError) {
      return (
        <div className="text-center">
          <ErrorMessageWithDetails
            error={ownedNFTsError}
            renderText="Something went wrong while getting your NFTs."
          />
        </div>
      );
    }

    // Render NFTs
    return (
      <div className="grid--fluid grid-container">
        <div className="grid__cards--small">{renderNFTCards(ownedNFTs)}</div>
      </div>
    );
  }

  /**
   * Render
   */

  return (
    <Modal
      keyProp="owned-nfts"
      isOpen={isOpen}
      isOpenHandler={() => {
        closeHandler();
      }}
      modalClassNames="modal-container--xl owned-nfts__modal">
      {/* MODEL CLOSE BUTTON */}
      <span
        className="modal__close-button"
        onClick={() => {
          closeHandler();
        }}>
        <TimesSVG />
      </span>

      {/* TITLE */}
      <div className="modal__title">Select</div>

      {renderModalContent()}
    </Modal>
  );
}
