import Modal from '../common/Modal';
import Muse0Text from '../common/Muse0Text';
import TimesSVG from '../../assets/svg/TimesSVG';

type NFTMemberTermsModalProps = {
  isOpen: boolean;
  closeHandler: () => void;
};

const GUIDING_PRINCIPLES_URL = 'https://docs.muse0.xyz/GuidingPrinciples.html';

export default function NFTMemberTermsModal({
  isOpen,
  closeHandler,
}: NFTMemberTermsModalProps): JSX.Element {
  /**
   * Render
   */

  return (
    <Modal
      keyProp="nft-member-terms"
      isOpen={isOpen}
      isOpenHandler={() => {
        closeHandler();
      }}
      modalClassNames="modal-container--lg nft-terms__modal">
      {/* MODEL CLOSE BUTTON */}
      <span
        className="modal__close-button"
        onClick={() => {
          closeHandler();
        }}>
        <TimesSVG />
      </span>

      {/* TITLE */}
      <div className="modal__title">Key Terms</div>

      {/* LIST OF TERMS */}
      <p className="nft-terms__text">
        Please read the{' '}
        <a
          style={{fontWeight: 700}}
          href={GUIDING_PRINCIPLES_URL}
          rel="noopener noreferrer"
          target="_blank">
          Guiding Principles
        </a>{' '}
        (the "Agreement") of <Muse0Text /> (the "DAO") in its entirety. If you
        receive <Muse0Text /> governance tokens or otherwise interact with the
        DAO's website, you are expressly agreeing that:
      </p>

      <ul className="nft-terms__list">
        <li>
          <Muse0Text /> is an unincorporated non-profit association organized in
          the United States and is entirely managed by its members. By joining{' '}
          <Muse0Text /> as a member, holding <Muse0Text />
          's governance token, or contributing an NFT to <Muse0Text />, you
          agree to the{' '}
          <a
            style={{fontWeight: 700}}
            href={GUIDING_PRINCIPLES_URL}
            rel="noopener noreferrer"
            target="_blank">
            Guiding Principles
          </a>{' '}
          and agree to be bound by this Agreement.
        </li>
        <li>
          If you claim <Muse0Text /> governance tokens or otherwise join the DAO
          as a member, you will not be entitled to any distributions of DAO
          assets (including NFTs). You will not be considered a fiduciary to
          other members and you will not become an owner or manager. You
          understand that the <Muse0Text /> governance tokens are
          non-transferrable, unless otherwise agreed to by members, and that the
          tokens only entitle you to vote on new donations to <Muse0Text /> and
          to direct the operations of <Muse0Text />.
        </li>
        <li>
          If you a donate an NFT to <Muse0Text />, you grant <Muse0Text /> a
          perpetual, non-exclusive, royalty-free worldwide license to use, edit,
          modify, display, or archive any copyrightable work associated with the
          NFT (the "Work"), in any manner and in any context, in connection with
          any activities of the DAO and its museum. You retain all other rights
          to the Work not otherwise granted to <Muse0Text />.
        </li>
        <li>
          You further represent and warrant that you are the owner of the
          entirety of the rights in and to the Work, and that you have full
          authorization to enter into the Agreement and grant to the DAO all
          requisite licenses to the Work. You also agree that you will not be
          entitled to receive back the NFT, unless otherwise agreed to by the
          DAO's members.
        </li>
      </ul>
    </Modal>
  );
}
