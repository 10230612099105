import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useInView} from 'react-intersection-observer';

import {ADMIN_ACCOUNTS} from '../../config';
import {useWeb3Modal} from '../web3/hooks/useWeb3Modal';
import {
  DaoAdapterConstants,
  VotingAdapterName,
} from '../adapters-extensions/enums';
import {AsyncStatus} from '../../util/types';
import {BURN_ADDRESS} from '../../util/constants';
import {ContractDAOConfigKeys} from '../web3/types';
import {FetchedNFT, NFTTokenData} from './types';
import {normalizeString} from '../../util/helpers';
import {OffchainVotingStatus} from '../proposals/voting';
import {ProposalData, ProposalFlag} from '../proposals/types';
import {
  calculateVotingTimeRanges,
  proposalHasFlag,
  proposalHasVotingState,
} from '../proposals/helpers';
import {ProposalHeaderNames} from '../../util/enums';
import {StoreState} from '../../store/types';
import {useIsDefaultChain} from '../web3/hooks';
import {useNFTAssets} from './hooks';
import {useProposals, useOffchainVotingResults} from '../proposals/hooks';
import {VotingState} from '../proposals/voting/types';
import ErrorMessageWithDetails from '../common/ErrorMessageWithDetails';
import LoaderLarge from '../feedback/LoaderLarge';
import NFTTributeProposalCard from './NFTTributeProposalCard';
import {useDaoConfigurations} from '../../hooks';

type NFTTributeProposalsProps = {
  adapterName: DaoAdapterConstants;
  /**
   * Optionally provide a click handler for `ProposalCard`.
   * The proposal's id (in the DAO) will be provided as an argument.
   * Defaults to noop: `() => {}`
   */
  onProposalClick?: (id: string) => void;
  /**
   * The path to link to. Defaults to `${location.pathname}/${proposalOnClickId}`.
   */
  proposalLinkPath?: Parameters<typeof NFTTributeProposalCard>['0']['linkPath'];
  /**
   * To handle proposal types where the first step is creating a snapshot
   * draft/offchain proposal only (no onchain proposal exists)
   */
  includeProposalsExistingOnlyOffchain?: boolean;
};

export type FilteredProposals = {
  failedProposals: ProposalData[];
  nonsponsoredProposals: ProposalData[];
  passedProposals: ProposalData[];
  votingProposals: ProposalData[];
};

type TokenDataForNFTAssets = {
  failedNFTs: NFTTokenData[];
  nonsponsoredNFTs: NFTTokenData[];
  passedNFTs: NFTTokenData[];
  votingNFTs: NFTTokenData[];
};

const configurationKeysToGet: ContractDAOConfigKeys[] = [
  ContractDAOConfigKeys.offchainVotingVotingPeriod,
  ContractDAOConfigKeys.offchainVotingGracePeriod,
];

export default function NFTTributeProposals(
  props: NFTTributeProposalsProps
): JSX.Element {
  const {
    adapterName,
    onProposalClick = () => {},
    proposalLinkPath,
    includeProposalsExistingOnlyOffchain = false,
  } = props;

  /**
   * Selectors
   */

  const isActiveMember = useSelector(
    (s: StoreState) => s.connectedMember?.isActiveMember
  );

  /**
   * State
   */

  const [proposalsForVotingResults, setProposalsForVotingResults] = useState<
    ProposalData['snapshotProposal'][]
  >([]);

  const [filteredProposals, setFilteredProposals] = useState<FilteredProposals>(
    {
      failedProposals: [],
      nonsponsoredProposals: [],
      passedProposals: [],
      votingProposals: [],
    }
  );

  const [tokenDataForNFTAssets, setTokenDataForNFTAssets] =
    useState<TokenDataForNFTAssets>({
      failedNFTs: [],
      nonsponsoredNFTs: [],
      passedNFTs: [],
      votingNFTs: [],
    });

  /**
   * Their hooks
   */

  const [failedProposalsRef, failedProposalsInView] = useInView({
    triggerOnce: true,
  });

  const [nonsponsoredProposalsRef, nonsponsoredProposalsInView] = useInView({
    triggerOnce: true,
  });

  const [passedProposalsRef, passedProposalsInView] = useInView({
    triggerOnce: true,
  });

  const [votingProposalsRef, votingProposalsInView] = useInView({
    triggerOnce: true,
  });

  /**
   * Our hooks
   */

  const {
    daoConfigurations: [offchainVotingPeriod, offchainGracePeriod],
  } = useDaoConfigurations(configurationKeysToGet);

  const {proposals, proposalsError, proposalsStatus} = useProposals(
    useMemo(
      () => ({
        adapterName,
        includeProposalsExistingOnlyOffchain,
      }),
      [adapterName, includeProposalsExistingOnlyOffchain]
    )
  );

  const {
    offchainVotingResults,
    offchainVotingResultsError,
    offchainVotingResultsStatus,
  } = useOffchainVotingResults(proposalsForVotingResults);

  const {failedNFTs, nonsponsoredNFTs, passedNFTs, votingNFTs} =
    tokenDataForNFTAssets;

  const {
    nfts: failedFetchedNFTs,
    nftsError: failedFetchedNFTsError,
    nftsStatus: failedFetchedNFTsStatus,
  } = useNFTAssets(failedNFTs);

  const {
    nfts: passedFetchedNFTs,
    nftsError: passedFetchedNFTsError,
    nftsStatus: passedFetchedNFTsStatus,
  } = useNFTAssets(passedNFTs);

  const {
    nfts: nonsponsoredFetchedNFTs,
    nftsError: nonsponsoredFetchedNFTsError,
    nftsStatus: nonsponsoredFetchedNFTsStatus,
  } = useNFTAssets(nonsponsoredNFTs);

  const {
    nfts: votingFetchedNFTs,
    nftsError: votingFetchedNFTsError,
    nftsStatus: votingFetchedNFTsStatus,
  } = useNFTAssets(votingNFTs);

  const {defaultChainError} = useIsDefaultChain();

  const {account} = useWeb3Modal();

  /**
   * Variables
   */

  const nfts = {
    failed: failedFetchedNFTs,
    nonsponsored: nonsponsoredFetchedNFTs,
    passed: passedFetchedNFTs,
    voting: votingFetchedNFTs,
  };

  const nftsStatus = {
    failed: failedFetchedNFTsStatus,
    nonsponsored: nonsponsoredFetchedNFTsStatus,
    passed: passedFetchedNFTsStatus,
    voting: votingFetchedNFTsStatus,
  };

  const {
    failedProposals,
    nonsponsoredProposals,
    passedProposals,
    votingProposals,
  } = filteredProposals;

  const isLoading: boolean =
    proposalsStatus === AsyncStatus.STANDBY ||
    proposalsStatus === AsyncStatus.PENDING ||
    (offchainVotingResultsStatus === AsyncStatus.STANDBY &&
      proposalsForVotingResults.length > 0) ||
    offchainVotingResultsStatus === AsyncStatus.PENDING;

  const error: Error | undefined =
    proposalsError || offchainVotingResultsError || defaultChainError;

  const connectedUserIsAdmin =
    ADMIN_ACCOUNTS &&
    account &&
    ADMIN_ACCOUNTS.split(',')
      .map((account) => normalizeString(account))
      .includes(normalizeString(account));

  /**
   * Effects
   */

  // Extract token info from failed proposals metadata
  useEffect(() => {
    if (failedProposalsInView) {
      const failedProposalsTokenData = failedProposals.map((p) => ({
        address:
          p.snapshotDraft?.msg.payload.metadata.tokenAddress ||
          p.snapshotProposal?.msg.payload.metadata.tokenAddress,
        tokenId:
          p.snapshotDraft?.msg.payload.metadata.tokenId ||
          p.snapshotProposal?.msg.payload.metadata.tokenId,
      }));

      setTokenDataForNFTAssets((prevState) => ({
        ...prevState,
        failedNFTs: failedProposalsTokenData,
      }));
    }
  }, [failedProposals, failedProposalsInView]);

  // Extract token info from nonsponsored proposals metadata
  useEffect(() => {
    if (nonsponsoredProposalsInView) {
      const nonsponsoredProposalsTokenData = nonsponsoredProposals.map((p) => ({
        address:
          p.snapshotDraft?.msg.payload.metadata.tokenAddress ||
          p.snapshotProposal?.msg.payload.metadata.tokenAddress,
        tokenId:
          p.snapshotDraft?.msg.payload.metadata.tokenId ||
          p.snapshotProposal?.msg.payload.metadata.tokenId,
      }));

      setTokenDataForNFTAssets((prevState) => ({
        ...prevState,
        nonsponsoredNFTs: nonsponsoredProposalsTokenData,
      }));
    }
  }, [nonsponsoredProposals, nonsponsoredProposalsInView]);

  // Extract token info from passed proposals metadata
  useEffect(() => {
    if (passedProposalsInView) {
      const passedProposalsTokenData = passedProposals.map((p) => ({
        address:
          p.snapshotDraft?.msg.payload.metadata.tokenAddress ||
          p.snapshotProposal?.msg.payload.metadata.tokenAddress,
        tokenId:
          p.snapshotDraft?.msg.payload.metadata.tokenId ||
          p.snapshotProposal?.msg.payload.metadata.tokenId,
      }));

      setTokenDataForNFTAssets((prevState) => ({
        ...prevState,
        passedNFTs: passedProposalsTokenData,
      }));
    }
  }, [passedProposals, passedProposalsInView]);

  // Extract token info from voting proposals metadata
  useEffect(() => {
    if (votingProposalsInView) {
      const votingProposalsTokenData = votingProposals.map((p) => ({
        address:
          p.snapshotDraft?.msg.payload.metadata.tokenAddress ||
          p.snapshotProposal?.msg.payload.metadata.tokenAddress,
        tokenId:
          p.snapshotDraft?.msg.payload.metadata.tokenId ||
          p.snapshotProposal?.msg.payload.metadata.tokenId,
      }));

      setTokenDataForNFTAssets((prevState) => ({
        ...prevState,
        votingNFTs: votingProposalsTokenData,
      }));
    }
  }, [votingProposals, votingProposalsInView]);

  // Extract snapshot proposal data to get voting results
  useEffect(() => {
    setProposalsForVotingResults(proposals.map((p) => p.snapshotProposal));
  }, [proposals]);

  // Separate proposals into categories: non-sponsored, voting, passed, failed
  useEffect(() => {
    if (proposalsStatus !== AsyncStatus.FULFILLED) return;

    const filteredProposalsToSet: FilteredProposals = {
      failedProposals: [],
      nonsponsoredProposals: [],
      passedProposals: [],
      votingProposals: [],
    };

    proposals.forEach((p) => {
      const {
        daoProposal,
        daoProposalVotingState: voteState,
        daoProposalVote: votesData,
      } = p;

      if (!daoProposal) return;

      const noSnapshotVotes: boolean = p.snapshotProposal?.votes?.length === 0;

      const offchainResultNotYetSubmitted: boolean =
        voteState !== undefined &&
        (proposalHasVotingState(VotingState.GRACE_PERIOD, voteState) ||
          proposalHasVotingState(VotingState.TIE, voteState)) &&
        proposalHasFlag(ProposalFlag.SPONSORED, daoProposal.flags) &&
        votesData?.OffchainVotingContract?.reporter === BURN_ADDRESS;

      // Non-sponsored proposal
      if (voteState === undefined) {
        if (includeProposalsExistingOnlyOffchain) {
          filteredProposalsToSet.nonsponsoredProposals.push(p);
        } else if (proposalHasFlag(ProposalFlag.EXISTS, daoProposal.flags)) {
          filteredProposalsToSet.nonsponsoredProposals.push(p);
        }

        return;
      }

      // Passed proposal
      if (
        voteState !== undefined &&
        proposalHasVotingState(VotingState.PASS, voteState) &&
        (proposalHasFlag(ProposalFlag.SPONSORED, daoProposal.flags) ||
          proposalHasFlag(ProposalFlag.PROCESSED, daoProposal.flags))
      ) {
        filteredProposalsToSet.passedProposals.push(p);

        return;
      }

      const offchainResult = offchainVotingResults.find(
        ([proposalHash, _result]) =>
          normalizeString(proposalHash) ===
          normalizeString(p.snapshotProposal?.idInDAO || '')
      )?.[1];

      // Did the vote pass by a simple majority?
      const didPassSimpleMajority: boolean = offchainResult
        ? offchainResult.Yes.units > offchainResult.No.units
        : false;

      /**
       * Voting proposal: voting has ended, off-chain result was not submitted,
       * and there are votes with a passing result (need to submit to get true
       * "passed" result).
       *
       * @note For now, we can assume across all adapters that if the vote did
       * not pass then the result does not need to be submitted (proposal would
       * fall back to "failed" logic).
       * @note Should be placed before "failed" logic.
       */
      if (
        offchainResultNotYetSubmitted &&
        noSnapshotVotes === false &&
        didPassSimpleMajority
      ) {
        filteredProposalsToSet.votingProposals.push(p);

        return;
      }

      // Failed proposal
      if (
        voteState !== undefined &&
        (proposalHasVotingState(VotingState.NOT_PASS, voteState) ||
          proposalHasVotingState(VotingState.TIE, voteState)) &&
        (proposalHasFlag(ProposalFlag.SPONSORED, daoProposal.flags) ||
          proposalHasFlag(ProposalFlag.PROCESSED, daoProposal.flags))
      ) {
        filteredProposalsToSet.failedProposals.push(p);

        return;
      }

      // Failed proposal: no Snapshot votes
      if (
        voteState !== undefined &&
        offchainResultNotYetSubmitted &&
        noSnapshotVotes
      ) {
        filteredProposalsToSet.failedProposals.push(p);

        return;
      }

      // Failed proposal: result not submitted; vote did not pass
      if (
        voteState !== undefined &&
        offchainResultNotYetSubmitted &&
        !didPassSimpleMajority
      ) {
        filteredProposalsToSet.failedProposals.push(p);

        return;
      }

      // Voting proposal
      if (
        voteState !== undefined &&
        (proposalHasVotingState(VotingState.GRACE_PERIOD, voteState) ||
          proposalHasVotingState(VotingState.IN_PROGRESS, voteState)) &&
        proposalHasFlag(ProposalFlag.SPONSORED, daoProposal.flags)
      ) {
        filteredProposalsToSet.votingProposals.push(p);

        return;
      }
    });

    setFilteredProposals((prevState) => ({
      ...prevState,
      ...filteredProposalsToSet,
    }));
  }, [
    includeProposalsExistingOnlyOffchain,
    offchainVotingResults,
    proposals,
    proposalsStatus,
  ]);

  /**
   * Functions
   */

  function renderProposalCards(
    proposals: ProposalData[],
    proposalsType: string
  ): React.ReactNode | null {
    return proposals.map((proposal) => {
      const {
        daoProposalVote,
        daoProposalVotingAdapter,
        snapshotDraft,
        snapshotProposal,
      } = proposal;

      const proposalId = snapshotDraft?.idInDAO || snapshotProposal?.idInDAO;
      const votingAdapterName = daoProposalVotingAdapter?.votingAdapterName;

      let gracePeriodEndMs: number = 0;
      let gracePeriodStartMs: number = 0;
      let voteEndMs: number = 0;
      let voteStartMs: number = 0;

      switch (votingAdapterName) {
        case VotingAdapterName.OffchainVotingContract:
          const {startingTime, gracePeriodStartingTime} =
            daoProposalVote?.[VotingAdapterName.OffchainVotingContract] || {};

          const times = calculateVotingTimeRanges({
            gracePeriodLength: offchainGracePeriod,
            gracePeriodStartingTime,
            votePeriodLength: offchainVotingPeriod,
            voteStartingTime: startingTime,
          });

          gracePeriodEndMs = times.gracePeriodEndMs;
          gracePeriodStartMs = times.gracePeriodStartMs;
          voteEndMs = times.voteEndMs;
          voteStartMs = times.voteStartMs;

          break;

        // @todo On-chain Voting
        // case VotingAdapterName.VotingContract:
        //   return <></>
        default:
          break;
      }

      if (!proposalId) return null;

      const tokenAddress =
        proposal.snapshotDraft?.msg.payload.metadata.tokenAddress ||
        proposal.snapshotProposal?.msg.payload.metadata.tokenAddress;

      const tokenId =
        proposal.snapshotDraft?.msg.payload.metadata.tokenId ||
        proposal.snapshotProposal?.msg.payload.metadata.tokenId;

      const nftData = (nfts[proposalsType] as FetchedNFT[]).find(
        (nft) =>
          normalizeString(nft.address) === normalizeString(tokenAddress) &&
          normalizeString(nft.tokenId) === normalizeString(tokenId)
      );

      const votingResult = offchainVotingResults.find(
        ([proposalHash, _result]) =>
          normalizeString(proposalHash) === normalizeString(proposalId)
      )?.[1];

      return (
        <NFTTributeProposalCard
          key={proposalId}
          linkPath={proposalLinkPath}
          nftData={nftData}
          nftProposalTypeStatus={nftsStatus[proposalsType]}
          onClick={onProposalClick}
          proposalOnClickId={proposalId}
          renderStatus={() => {
            switch (votingAdapterName) {
              case VotingAdapterName.OffchainVotingContract:
                return (
                  <OffchainVotingStatus
                    countdownGracePeriodEndMs={gracePeriodEndMs}
                    countdownGracePeriodStartMs={gracePeriodStartMs}
                    countdownVotingEndMs={voteEndMs}
                    countdownVotingStartMs={voteStartMs}
                    votingResult={votingResult}
                  />
                );
              // @todo On-chain Voting
              // case VotingAdapterName.VotingContract:
              //   return <></>
              default:
                return <></>;
            }
          }}
        />
      );
    });
  }

  function renderFailedProposalsContent(): JSX.Element {
    // Render error
    if (failedFetchedNFTsError) {
      return (
        <div className="text-center">
          <ErrorMessageWithDetails
            error={failedFetchedNFTsError}
            renderText="Something went wrong while getting the failed NFTs."
          />
        </div>
      );
    }

    // Render proposal cards
    return (
      <div className="grid__cards">
        {renderProposalCards(failedProposals, 'failed')}
      </div>
    );
  }

  function renderNonsponsoredProposalsContent(): JSX.Element {
    // Render error
    if (nonsponsoredFetchedNFTsError) {
      return (
        <div className="text-center">
          <ErrorMessageWithDetails
            error={nonsponsoredFetchedNFTsError}
            renderText="Something went wrong while getting the pending NFTs."
          />
        </div>
      );
    }

    // Render proposal cards
    return (
      <div className="grid__cards">
        {renderProposalCards(nonsponsoredProposals, 'nonsponsored')}
      </div>
    );
  }

  function renderPassedProposalsContent(): JSX.Element {
    // Render error
    if (passedFetchedNFTsError) {
      return (
        <div className="text-center">
          <ErrorMessageWithDetails
            error={passedFetchedNFTsError}
            renderText="Something went wrong while getting the selected NFTs."
          />
        </div>
      );
    }

    // Render proposal cards
    return (
      <div className="grid__cards">
        {renderProposalCards(passedProposals, 'passed')}
      </div>
    );
  }

  function renderVotingProposalsContent(): JSX.Element {
    // Render error
    if (votingFetchedNFTsError) {
      return (
        <div className="text-center">
          <ErrorMessageWithDetails
            error={votingFetchedNFTsError}
            renderText="Something went wrong while getting the NFTs up for vote."
          />
        </div>
      );
    }

    // Render proposal cards
    return (
      <div className="grid__cards">
        {renderProposalCards(votingProposals, 'voting')}
      </div>
    );
  }

  /**
   * Render
   */

  // Render loading
  if (isLoading && !error) {
    return (
      <div className="loader--large-container">
        <LoaderLarge />
      </div>
    );
  }

  // Render error
  if (error) {
    return (
      <div className="text-center">
        <ErrorMessageWithDetails
          error={error}
          renderText="Something went wrong while getting the proposals."
        />
      </div>
    );
  }

  // Render no proposals
  if (
    !Object.values(filteredProposals).flatMap((p) => p).length &&
    proposalsStatus === AsyncStatus.FULFILLED
  ) {
    return <p className="text-center">No proposals, yet!</p>;
  }

  // Render proposals
  return (
    <div className="grid--fluid grid-container">
      {/* VOTING PROPOSALS */}
      {votingProposals.length > 0 && (
        <div ref={votingProposalsRef}>
          <div className="grid__header">{ProposalHeaderNames.VOTING}</div>
          {renderVotingProposalsContent()}
        </div>
      )}

      {/* PENDING PROPOSALS (DRAFTS, NOT SPONSORED) */}
      {nonsponsoredProposals.length > 0 && (
        <div ref={nonsponsoredProposalsRef}>
          <div className="grid__header">{ProposalHeaderNames.REQUESTS}</div>
          {renderNonsponsoredProposalsContent()}
        </div>
      )}

      {/* PASSED PROPOSALS */}
      {passedProposals.length > 0 && (
        <div ref={passedProposalsRef}>
          <div className="grid__header">{ProposalHeaderNames.SELECTED}</div>
          {renderPassedProposalsContent()}
        </div>
      )}

      {/* FAILED PROPOSALS (can only be viewed by members and admin accounts) */}
      {failedProposals.length > 0 && (isActiveMember || connectedUserIsAdmin) && (
        <div ref={failedProposalsRef}>
          <div className="grid__header">{ProposalHeaderNames.FAILED}</div>
          {renderFailedProposalsContent()}
        </div>
      )}
    </div>
  );
}
