/**
 * isIframeSafeFormat
 *
 * Checks if src URL is a trusted format to display in iframe
 *
 * Trusted sources: https://generator.artblocks.io/,
 * https://api.artblocks.io/generator/, https://api.gen.art/public/live/
 *
 * @param {string} url
 * @returns {boolean}
 */
export function isIframeSafeFormat(url: string): boolean {
  const regex =
    /^https:\/\/(?:generator\.artblocks\.io\/|api\.artblocks.io\/generator\/|api\.gen\.art\/public\/live\/)/i;
  return regex.test(url);
}
