import {useEffect, useState} from 'react';

import {OPENSEA_ASSETS_URL} from '../../config';
import {FetchedNFT} from './types';
import {
  is3DModelFormat,
  isHTMLVideoFormat,
  isIframeSafeFormat,
} from './helpers';
import TimesSVG from '../../assets/svg/TimesSVG';
import ExternalLinkSVG from '../../assets/svg/ExternalLinkSVG';

type NFTPreviewProps = {
  nft?: FetchedNFT;
  clearNFTHandler: () => void;
  clearNFTHandlerDisabled: boolean;
};

const PLACEHOLDER = '\u2014'; /* em dash */

export default function NFTPreview(props: NFTPreviewProps): JSX.Element {
  const {nft, clearNFTHandler, clearNFTHandlerDisabled} = props;

  /**
   * State
   */

  const [imageDataURL, setImageDataURL] = useState<string>();

  /**
   * Effects
   */

  useEffect(() => {
    if (nft?.image_data) {
      const svg = nft.image_data;
      const blob = new Blob([svg], {type: 'image/svg+xml'});
      setImageDataURL(URL.createObjectURL(blob));
    }

    return function cleanup() {
      // Make sure to revoke the data URIs to avoid memory leaks
      imageDataURL && URL.revokeObjectURL(imageDataURL);
    };
  }, [imageDataURL, nft?.image_data]);

  /**
   * Functions
   */

  function renderInfoColumn(): React.ReactNode {
    if (!nft) return;

    return (
      <div className="nft-preview__info-column">
        <div className="nft-preview__title">
          {nft.tokenURIName || PLACEHOLDER}
        </div>
        <div className="nft-preview__subtitle">
          {nft.tokenName || PLACEHOLDER}
        </div>
        <div className="nft-preview__text">
          <a
            className="nft-preview__link"
            href={`${OPENSEA_ASSETS_URL}/${nft.address.toLowerCase()}/${
              nft.tokenId
            }`}
            target="_blank"
            rel="noopener noreferrer">
            view details
            <ExternalLinkSVG width="12px" height="12px" />
          </a>
        </div>
      </div>
    );
  }

  function renderImage(): React.ReactNode {
    if (!nft) return;

    // detect video file first to display
    if (nft.animation_url) {
      return (
        <div className="thumb">
          {isHTMLVideoFormat(nft.animation_url) ? (
            <video
              className="preview-file"
              controls
              autoPlay
              controlsList="nodownload"
              loop
              playsInline
              src={nft.animation_url}
            />
          ) : is3DModelFormat(nft.animation_url) ? (
            <model-viewer
              src={nft.animation_url}
              auto-rotate
              autoplay
              camera-controls
              ar-status="not-presenting"></model-viewer>
          ) : isIframeSafeFormat(nft.animation_url) ? (
            <iframe
              className="preview-file"
              src={nft.animation_url}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title="preview"
            />
          ) : (
            // fall back to placeholder text if src is not trusted format
            <div className="no-image-available">Media type not supported</div>
          )}
        </div>
      );
    }

    // then raw image data (e.g., svg)
    if (nft.image_data) {
      return (
        <div className="thumb">
          <img className="preview-file" src={imageDataURL} alt="preview" />
        </div>
      );
    }

    // then image file
    if (nft.image_url) {
      return (
        <div className="thumb">
          {/* Handle metadata where a video file is set for `image_url` (instead of the expected `animation_url`) */}
          {isHTMLVideoFormat(nft.image_url) ? (
            <video
              className="preview-file"
              controls
              autoPlay
              controlsList="nodownload"
              loop
              playsInline
              src={nft.image_url}
            />
          ) : (
            <img className="preview-file" src={nft.image_url} alt="preview" />
          )}
        </div>
      );
    }

    return <div className="no-image-available">No image available</div>;
  }

  /**
   * Render
   */

  // Render NFT
  if (nft) {
    return (
      <div className="nft-preview">
        {/* CLEAR NFT BUTTON */}
        <span
          className="nft-preview__close-button"
          onClick={() => (clearNFTHandlerDisabled ? {} : clearNFTHandler())}>
          <TimesSVG />
        </span>
        <div className="nft-preview__image-column">
          <div className="nft-preview__image-container">{renderImage()}</div>
        </div>
        {renderInfoColumn()}
      </div>
    );
  }

  // Render nothing if no NFT selected
  return <></>;
}
