import DiscordSVG from '../../assets/svg/DiscordSVG';
import MediumSVG from '../../assets/svg/MediumSVG';
import TwitterSVG from '../../assets/svg/TwitterSVG';

enum SocialMediaLinks {
  DISCORD = 'https://discord.gg/M6Vvvr83j3',
  MEDIUM = 'https://medium.com/@MUSE0DAO',
  TWITTER = 'https://twitter.com/MUSE0DAO',
}

export default function SocialMedia() {
  return (
    <div className="socialmedia">
      <a
        href={SocialMediaLinks.TWITTER}
        target="_blank"
        rel="noopener noreferrer">
        <TwitterSVG />
      </a>
      <a
        href={SocialMediaLinks.MEDIUM}
        target="_blank"
        rel="noopener noreferrer">
        <MediumSVG />
      </a>
      <a
        href={SocialMediaLinks.DISCORD}
        target="_blank"
        rel="noopener noreferrer">
        <DiscordSVG />
      </a>
    </div>
  );
}
