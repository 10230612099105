import React from 'react';
import {useHistory} from 'react-router-dom';

import {DaoAdapterConstants} from '../../../components/adapters-extensions/enums';
import FadeIn from '../../../components/common/FadeIn';
import Wrap from '../../../components/common/Wrap';
import NFTTributeProposals from '../../../components/nfts/NFTTributeProposals';

export default function NFTTributes() {
  /**
   * Functions
   */

  function proposalLinkPath(id: string) {
    return `/curation/${id}`;
  }

  /**
   * Render
   */

  return (
    <RenderWrapper>
      <NFTTributeProposals
        adapterName={DaoAdapterConstants.TRIBUTE_NFT}
        proposalLinkPath={proposalLinkPath}
        includeProposalsExistingOnlyOffchain={true}
      />
    </RenderWrapper>
  );
}

function RenderWrapper(props: React.PropsWithChildren<any>): JSX.Element {
  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * Functions
   */

  function goToNewProposal(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    history.push('/donate');
  }

  /**
   * Render
   */

  return (
    <Wrap className="section-wrapper">
      <FadeIn>
        <div className="titlebar">
          <div className="titlebar__title-container">
            <h2 className="titlebar__title">Curate</h2>
            <div className="titlebar__subtitle">
              <p>
                Curation is handled by existing members who choose which NFTs to
                accept. Accepted artists participate in subsequent curation.
              </p>
            </div>
          </div>
          <button className="titlebar__action" onClick={goToNewProposal}>
            Donate
          </button>
        </div>

        {/* RENDER CHILDREN */}
        {props.children}
      </FadeIn>
    </Wrap>
  );
}
