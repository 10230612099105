import {SVGAttributes} from 'react';

export default function RightArrowCircleSVG(
  props: SVGAttributes<HTMLOrSVGElement>
) {
  return (
    <svg
      width="60px"
      height="60px"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g opacity="0.7">
        <circle cx="37.5" cy="37.5" r="37" fill="white" stroke="currentColor" />
        <path
          d="M31.7117 52.1548L31.485 26.175L54.0975 38.9686L31.7117 52.1548Z"
          fill="currentColor"
          stroke="currentColor"
        />
      </g>
    </svg>
  );
}
