import {useState, useCallback, useEffect} from 'react';
import {toChecksumAddress} from 'web3-utils';
import usePreviousDistinct from 'react-use/lib/usePreviousDistinct';
import {useQuery} from 'react-query';

import {FetchedNFT} from '../types';
import {getNFTAssetsByOwner} from '../helpers';
import {AsyncStatus} from '../../../util/types';
import {NFT_QUERY_KEY} from './useNFTAsset';

type UseNFTAssetsByOwnerReturn = {
  ownedNFTs: FetchedNFT[];
  ownedNFTsStatus: AsyncStatus;
  ownedNFTsError: Error | undefined;
};

/**
 * useNFTAssetsByOwner
 *
 * Gets the NFT assets owned by an address.
 *
 * @param {string} ownerAddress
 * @returns {UseNFTAssetsByOwnerReturn} An object with the NFT assets data and the current async status.
 */
export function useNFTAssetsByOwner(
  ownerAddress?: string
): UseNFTAssetsByOwnerReturn {
  /**
   * State
   */

  const [ownedNFTs, setOwnedNFTs] = useState<FetchedNFT[]>([]);
  const [ownedNFTsStatus, setOwnedNFTsStatus] = useState<AsyncStatus>(
    AsyncStatus.STANDBY
  );
  const [ownedNFTsError, setOwnedNFTsError] = useState<Error>();

  /**
   * Their hooks
   */

  const prevOwnerAddress = usePreviousDistinct(ownerAddress);

  /**
   * React Query
   */

  const {data: nftAssetsByOwnerData, error: nftAssetsByOwnerError} = useQuery(
    [NFT_QUERY_KEY, 'nftAssetsByOwner', ownerAddress],
    async () => {
      if (!ownerAddress) return;

      return await getNFTAssetsByOwner(ownerAddress);
    },
    {enabled: !!ownerAddress}
  );

  /**
   * Cached callbacks
   */

  const getOwnedNFTsDataCached = useCallback(getOwnedNFTsData, [
    nftAssetsByOwnerData,
    nftAssetsByOwnerError,
    ownerAddress,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    // Reset state if no owner account is detected
    if (prevOwnerAddress && !ownerAddress) {
      setOwnedNFTs([]);
      setOwnedNFTsError(undefined);
      setOwnedNFTsStatus(AsyncStatus.STANDBY);
    }

    if (ownerAddress) {
      getOwnedNFTsDataCached();
    }
  }, [getOwnedNFTsDataCached, ownerAddress, prevOwnerAddress]);

  /**
   * Functions
   */

  async function getOwnedNFTsData() {
    if (!ownerAddress) return;

    try {
      setOwnedNFTsStatus(AsyncStatus.PENDING);

      if (nftAssetsByOwnerError) {
        throw nftAssetsByOwnerError;
      }

      if (nftAssetsByOwnerData) {
        const fetchedOwnedNFTs = nftAssetsByOwnerData;

        const nftsData = fetchedOwnedNFTs
          .map((asset: any) => {
            const {
              animation_url,
              asset_contract,
              description,
              external_link,
              image_data,
              image_url,
              name: tokenURIName,
              token_id,
            } = asset;
            const {
              address: tokenAddress,
              name: tokenName,
              schema_name: schema,
              symbol: tokenSymbol,
            } = asset_contract;

            return {
              address: toChecksumAddress(tokenAddress),
              animation_url,
              description,
              external_link,
              image_data,
              image_url,
              owner: toChecksumAddress(ownerAddress),
              schema,
              tokenId: token_id,
              tokenName,
              tokenSymbol,
              tokenURIName,
            };
          })
          // Sort by NFT contract name (asc) then contract address (asc) then
          // tokenId (asc).
          .sort(
            (a, b) =>
              a.tokenName.localeCompare(b.tokenName) ||
              a.address.localeCompare(b.address) ||
              Number(a.tokenId) - Number(b.tokenId)
          );

        setOwnedNFTs(nftsData);
        setOwnedNFTsStatus(AsyncStatus.FULFILLED);
      }
    } catch (error) {
      setOwnedNFTs([]);
      setOwnedNFTsError(error);
      setOwnedNFTsStatus(AsyncStatus.REJECTED);
    }
  }

  return {ownedNFTs, ownedNFTsError, ownedNFTsStatus};
}
