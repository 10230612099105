import {gql} from '@apollo/client';

export const GET_NFT_COLLECTION = gql`
  query GetNFTCollection($daoAddress: String) {
    tributeDaos(where: {daoAddress: $daoAddress}) {
      nftCollection {
        nfts(first: 1000) {
          address: nftAddress
          tokenId
        }
      }
    }
  }
`;
