import React, {useState, useEffect, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import ReactModal from 'react-modal';

import {OPENSEA_ASSETS_URL} from '../../config';
import {normalizeString} from '../../util/helpers';
import {AsyncStatus} from '../../util/types';
import {FetchedNFT} from '../../components/nfts/types';
import {
  is3DModelFormat,
  isHTMLVideoFormat,
  isIframeSafeFormat,
} from '../../components/nfts/helpers';
import FadeIn from '../../components/common/FadeIn';
import Loader from '../../components/feedback/Loader';
import TimesSVG from '../../assets/svg/TimesSVG';
import ExternalLinkSVG from '../../assets/svg/ExternalLinkSVG';
import LeftArrowCircleSVG from '../../assets/svg/LeftArrowCircleSVG';
import RightArrowCircleSVG from '../../assets/svg/RightArrowCircleSVG';

type NFTDetailsModalProps = {
  isOpen: boolean;
  nfts: FetchedNFT[];
  tokenAddress?: string;
  tokenId?: string;
};

const PLACEHOLDER = '\u2014'; /* em dash */

export default function NFTDetailsModal({
  isOpen,
  nfts,
  tokenAddress,
  tokenId,
}: NFTDetailsModalProps): JSX.Element {
  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * State
   */

  const [imageDataURL, setImageDataURL] = useState<string>();
  const [selectedNFT, setSelectedNFT] = useState<FetchedNFT>();
  const [selectedNFTIndex, setSelectedNFTIndex] = useState<number>();
  const [selectedNFTStatus, setSelectedNFTStatus] = useState<AsyncStatus>(
    AsyncStatus.STANDBY
  );

  /**
   * Cached callbacks
   */

  const getNFTFromCollectionCached = useCallback(getNFTFromCollection, [
    nfts,
    tokenAddress,
    tokenId,
  ]);

  /**
   * Variables
   */

  const isLoading: boolean =
    selectedNFTStatus === AsyncStatus.STANDBY ||
    selectedNFTStatus === AsyncStatus.PENDING;
  const isError: boolean = selectedNFTStatus === AsyncStatus.REJECTED;
  const showNavButton: boolean =
    !isLoading &&
    !isError &&
    !!selectedNFT &&
    typeof selectedNFTIndex === 'number' &&
    selectedNFTIndex > -1 &&
    // Nav buttons are not necessary if there is only 1 NFT in the collection
    nfts.length > 1;

  /**
   * Effects
   */

  useEffect(() => {
    if (selectedNFT?.image_data) {
      const svg = selectedNFT.image_data;
      const blob = new Blob([svg], {type: 'image/svg+xml'});
      setImageDataURL(URL.createObjectURL(blob));
    }

    return function cleanup() {
      // Make sure to revoke the data URIs to avoid memory leaks
      imageDataURL && URL.revokeObjectURL(imageDataURL);
    };
  }, [imageDataURL, selectedNFT?.image_data]);

  useEffect(() => {
    getNFTFromCollectionCached();
  }, [getNFTFromCollectionCached]);

  /**
   * Functions
   */

  function getNFTFromCollection() {
    if (!tokenAddress || !tokenId) return;

    setSelectedNFTStatus(AsyncStatus.PENDING);

    const nftIndex = nfts.findIndex(
      (nft) =>
        normalizeString(nft.address) === normalizeString(tokenAddress) &&
        normalizeString(nft.tokenId) === normalizeString(tokenId)
    );

    if (nftIndex > -1) {
      setSelectedNFTIndex(nftIndex);
      setSelectedNFT(nfts[nftIndex]);
      setSelectedNFTStatus(AsyncStatus.FULFILLED);
    } else {
      setSelectedNFTIndex(undefined);
      setSelectedNFT(undefined);
      setSelectedNFTStatus(AsyncStatus.REJECTED);
    }
  }

  function goToAll(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    history.push('/collection');
  }

  function goToPrevious(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    if (typeof selectedNFTIndex !== 'number' || selectedNFTIndex < 0) return;

    let targetNFTAddress;
    let targetNFTTokenId;
    if (selectedNFTIndex === 0) {
      const nbNFTs = nfts.length;
      const lastNFT = nfts[nbNFTs - 1];
      targetNFTAddress = lastNFT.address;
      targetNFTTokenId = lastNFT.tokenId;
    } else {
      const previousNFT = nfts[selectedNFTIndex - 1];
      targetNFTAddress = previousNFT.address;
      targetNFTTokenId = previousNFT.tokenId;
    }

    history.push(
      `/collection?address=${targetNFTAddress}&tokenId=${targetNFTTokenId}`
    );
  }

  function goToNext(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    if (typeof selectedNFTIndex !== 'number' || selectedNFTIndex < 0) return;

    const nbNFTs = nfts.length;

    let targetNFTAddress;
    let targetNFTTokenId;
    if (selectedNFTIndex === nbNFTs - 1) {
      const firstNFT = nfts[0];
      targetNFTAddress = firstNFT.address;
      targetNFTTokenId = firstNFT.tokenId;
    } else {
      const nextNFT = nfts[selectedNFTIndex + 1];
      targetNFTAddress = nextNFT.address;
      targetNFTTokenId = nextNFT.tokenId;
    }

    history.push(
      `/collection?address=${targetNFTAddress}&tokenId=${targetNFTTokenId}`
    );
  }

  function renderNFTDetails(): React.ReactNode {
    // Render loading
    if (isLoading && !isError) {
      return (
        <div>
          <Loader text="Loading NFT..." />
        </div>
      );
    }

    // Render error
    if (isError) {
      return (
        <div className="error-message ">
          NFT not found in the collection. The address and/or tokenId (in the
          page URL) may be invalid.
        </div>
      );
    }

    // Render NFT
    if (selectedNFT) {
      return (
        <>
          {/* IMAGE */}
          <div className="nft-card__image-container">{renderImage()}</div>

          {/* TITLE */}
          <h3 className="nft-card__title">
            {selectedNFT.tokenURIName || PLACEHOLDER}
          </h3>

          {/* SUBTITLE */}
          <div className="nft-card__subtitle">
            {selectedNFT.tokenName || PLACEHOLDER}
          </div>

          {/* LINK */}
          <a
            className="nft-preview__link"
            href={`${OPENSEA_ASSETS_URL}/${selectedNFT.address.toLowerCase()}/${
              selectedNFT.tokenId
            }`}
            target="_blank"
            rel="noopener noreferrer">
            <span>view details</span>
            <ExternalLinkSVG width="12px" height="12px" />
          </a>
        </>
      );
    }
  }

  function renderImage(): React.ReactNode {
    if (!selectedNFT) return;

    // detect video file first to display
    if (selectedNFT.animation_url) {
      return (
        <div className="thumb">
          {isHTMLVideoFormat(selectedNFT.animation_url) ? (
            <video
              className="preview-file"
              controls
              autoPlay
              controlsList="nodownload"
              loop
              playsInline
              src={selectedNFT.animation_url}
            />
          ) : is3DModelFormat(selectedNFT.animation_url) ? (
            <model-viewer
              src={selectedNFT.animation_url}
              auto-rotate
              autoplay
              camera-controls
              ar-status="not-presenting"></model-viewer>
          ) : isIframeSafeFormat(selectedNFT.animation_url) ? (
            <iframe
              className="preview-file"
              src={selectedNFT.animation_url}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title="preview"
            />
          ) : (
            // fall back to placeholder text if src is not trusted format
            <div className="no-image-available">Media type not supported</div>
          )}
        </div>
      );
    }

    // then raw image data (e.g., svg)
    if (selectedNFT.image_data) {
      return (
        <div className="thumb">
          <img className="preview-file" src={imageDataURL} alt="preview" />
        </div>
      );
    }

    // then image file
    if (selectedNFT.image_url) {
      return (
        <div className="thumb">
          {/* Handle metadata where a video file is set for `image_url` (instead of the expected `animation_url`) */}
          {isHTMLVideoFormat(selectedNFT.image_url) ? (
            <video
              className="preview-file"
              controls
              autoPlay
              controlsList="nodownload"
              loop
              playsInline
              src={selectedNFT.image_url}
            />
          ) : (
            <img
              className="preview-file"
              src={selectedNFT.image_url}
              alt="preview"
            />
          )}
        </div>
      );
    }

    return <div className="no-image-available">No image available</div>;
  }

  /**
   * Render
   */

  return (
    <ReactModal
      key="nft-details"
      ariaHideApp={false}
      className="modal-container--lg nft-details__modal"
      isOpen={isOpen}
      onRequestClose={goToAll}
      overlayClassName="modal-overlay"
      role="dialog"
      style={
        {
          overlay: {zIndex: '99'},
        } as any
      }>
      <FadeIn>
        <div className="nft-details__nav-container">
          {showNavButton && (
            <span className="nft-details__nav-button" onClick={goToPrevious}>
              <LeftArrowCircleSVG />
            </span>
          )}

          <div className="modal">
            {/* MODEL CLOSE BUTTON */}
            <span className="modal__close-button" onClick={goToAll}>
              <TimesSVG />
            </span>

            <div className="nft-details__nft-wrapper">{renderNFTDetails()}</div>
          </div>

          {showNavButton && (
            <span className="nft-details__nav-button" onClick={goToNext}>
              <RightArrowCircleSVG />
            </span>
          )}
        </div>
      </FadeIn>
    </ReactModal>
  );
}
