interface LogoProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function Logo({size}: LogoProps) {
  return (
    <div
      data-text="MUSE0"
      className={`logo ${size ? `logo--${size}` : ''} ${
        size === 'large' ? 'glitch' : ''
      }`}>
      MUSE<span className="logo__last-character">0</span>
    </div>
  );
}
