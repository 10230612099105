import {useLocation} from 'react-router-dom';

/**
 * useQueryParams
 *
 * A custom hook that builds on useLocation to parse the query string for you.
 */
export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}
