import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory, Link} from 'react-router-dom';
import {SnapshotType} from '@openlaw/snapshot-js-erc712';
import {toChecksumAddress} from 'web3-utils';

import {CycleEllipsis} from '../../../components/feedback';
import {AsyncStatus} from '../../../util/types';
import {FormFieldErrors} from '../../../util/enums';
import {isEthAddressValid, isEmailValid} from '../../../util/validation';
import {
  getValidationError,
  normalizeString,
  truncateEthAddress,
} from '../../../util/helpers';
import {BURN_ADDRESS} from '../../../util/constants';
import {
  useCheckApplicant,
  useSignAndSubmitProposal,
  useProposals,
  useOffchainVotingResults,
} from '../../../components/proposals/hooks';
import {ProposalData, ProposalFlag} from '../../../components/proposals/types';
import {
  proposalHasFlag,
  proposalHasVotingState,
} from '../../../components/proposals/helpers';
import {VotingState} from '../../../components/proposals/voting/types';
import {useWeb3Modal, useIsDefaultChain} from '../../../components/web3/hooks';
import {
  ContractAdapterNames,
  Web3TxStatus,
} from '../../../components/web3/types';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../../components/common/FadeIn';
import InputError from '../../../components/common/InputError';
import {CheckboxSize} from '../../../components/common/Checkbox';
import Loader from '../../../components/feedback/Loader';
import PreviewInputMarkdown from '../../../components/common/PreviewInputMarkdown';
import Wrap from '../../../components/common/Wrap';
import Muse0Text from '../../../components/common/Muse0Text';
import {
  useIsCollectedNFT,
  useNFTAssetsByOwner,
} from '../../../components/nfts/hooks';
import {FetchedNFT} from '../../../components/nfts/types';
import NFTPreview from '../../../components/nfts/NFTPreview';
import OwnedNFTsModal from '../../../components/nfts/OwnedNFTsModal';
import NFTMemberTermsModal from '../../../components/nfts/NFTMemberTermsModal';
import {FilteredProposals} from '../../../components/nfts/NFTTributeProposals';
import {DaoAdapterConstants} from '../../../components/adapters-extensions/enums';
import ImageSVG from '../../../assets/svg/ImageSVG';
import {DAO_NFT_ADDITIONAL_OWNER} from '../../../config';

enum Fields {
  applicantAddress = 'applicantAddress',
  emailAddress = 'emailAddress',
  description = 'description',
  termsAccept = 'termsAccept',
}

type FormInputs = {
  applicantAddress: string;
  emailAddress: string;
  description: string;
  termsAccept: boolean;
};

type SubmitActionArguments = [
  string, // `applicant`
  string, // `nftAddr`
  string, // `nftTokenId
  string // `requestAmount`
];

const PLACEHOLDER = '\u2014'; /* em dash */

export default function CreateNFTTributeProposal() {
  /**
   * State
   */

  const [submitError, setSubmitError] = useState<
    Error | (() => React.ReactElement)
  >();
  const [showOwnedNFTsModal, setShowOwnedNFTsModal] = useState<boolean>(false);
  const [selectedNFT, setSelectedNFT] = useState<FetchedNFT>();
  const [filteredProposals, setFilteredProposals] = useState<FilteredProposals>(
    {
      failedProposals: [],
      nonsponsoredProposals: [],
      passedProposals: [],
      votingProposals: [],
    }
  );
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const [proposalsForVotingResults, setProposalsForVotingResults] = useState<
    ProposalData['snapshotProposal'][]
  >([]);

  /**
   * Our hooks
   */

  const {defaultChainError} = useIsDefaultChain();
  const {connected, account} = useWeb3Modal();
  const {
    proposalData,
    proposalSignAndSendError,
    proposalSignAndSendStatus,
    signAndSendProposal,
  } = useSignAndSubmitProposal<SnapshotType.draft>();
  const {isCollectedNFT, isCollectedNFTError, isCollectedNFTStatus} =
    useIsCollectedNFT(selectedNFT?.address, selectedNFT?.tokenId);
  const {
    ownedNFTs: daoOwnedNFTs,
    ownedNFTsError: daoOwnedNFTsError,
    ownedNFTsStatus: daoOwnedNFTsStatus,
  } = useNFTAssetsByOwner(DAO_NFT_ADDITIONAL_OWNER);
  const {proposals, proposalsError, proposalsStatus} = useProposals({
    adapterName: DaoAdapterConstants.TRIBUTE_NFT,
    includeProposalsExistingOnlyOffchain: true,
  });
  const {
    offchainVotingResults,
    offchainVotingResultsError,
    offchainVotingResultsStatus,
  } = useOffchainVotingResults(proposalsForVotingResults);

  /**
   * Their hooks
   */

  const form = useForm<FormInputs>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const history = useHistory();

  /**
   * Variables
   */

  const {errors, getValues, setValue, register, trigger, watch} = form;
  const isConnected = connected && account;
  const isInProcess =
    proposalSignAndSendStatus === Web3TxStatus.AWAITING_CONFIRM ||
    proposalSignAndSendStatus === Web3TxStatus.PENDING;
  const isDone = proposalSignAndSendStatus === Web3TxStatus.FULFILLED;
  const isInProcessOrDone = isInProcess || isDone;
  const createNFTTributeError = submitError || proposalSignAndSendError;
  const {nonsponsoredProposals, passedProposals, votingProposals} =
    filteredProposals;
  const applicantAddressValue = watch(Fields.applicantAddress);
  const {
    checkApplicantError,
    checkApplicantInvalidMsg,
    checkApplicantStatus,
    isApplicantValid,
  } = useCheckApplicant(applicantAddressValue);
  const checkDuplicateProposalError: Error | undefined =
    proposalsError || offchainVotingResultsError;
  const checkNFTOwnerError: Error | undefined =
    isCollectedNFTError || daoOwnedNFTsError;

  /**
   * Effects
   */

  // Set the value of `applicantAddress` if the `account` changes
  useEffect(() => {
    setValue(Fields.applicantAddress, account && toChecksumAddress(account));
  }, [account, setValue]);

  // Clear the selected NFT if the `account` changes
  useEffect(() => {
    setSelectedNFT(undefined);
  }, [account]);

  useEffect(() => {
    setProposalsForVotingResults(proposals.map((p) => p.snapshotProposal));
  }, [proposals]);

  // Separate proposals into categories: non-sponsored, voting, passed, failed
  useEffect(() => {
    if (proposalsStatus !== AsyncStatus.FULFILLED) return;

    const filteredProposalsToSet: FilteredProposals = {
      failedProposals: [],
      nonsponsoredProposals: [],
      passedProposals: [],
      votingProposals: [],
    };

    proposals.forEach((p) => {
      const {
        daoProposal,
        daoProposalVotingState: voteState,
        daoProposalVote: votesData,
      } = p;

      if (!daoProposal) return;

      const noSnapshotVotes: boolean = p.snapshotProposal?.votes?.length === 0;

      const offchainResultNotYetSubmitted: boolean =
        voteState !== undefined &&
        (proposalHasVotingState(VotingState.GRACE_PERIOD, voteState) ||
          proposalHasVotingState(VotingState.TIE, voteState)) &&
        proposalHasFlag(ProposalFlag.SPONSORED, daoProposal.flags) &&
        votesData?.OffchainVotingContract?.reporter === BURN_ADDRESS;

      // Non-sponsored proposal
      if (voteState === undefined) {
        filteredProposalsToSet.nonsponsoredProposals.push(p);

        return;
      }

      // Passed proposal
      if (
        voteState !== undefined &&
        proposalHasVotingState(VotingState.PASS, voteState) &&
        (proposalHasFlag(ProposalFlag.SPONSORED, daoProposal.flags) ||
          proposalHasFlag(ProposalFlag.PROCESSED, daoProposal.flags))
      ) {
        filteredProposalsToSet.passedProposals.push(p);

        return;
      }

      const offchainResult = offchainVotingResults.find(
        ([proposalHash, _result]) =>
          normalizeString(proposalHash) ===
          normalizeString(p.snapshotProposal?.idInDAO || '')
      )?.[1];

      // Did the vote pass by a simple majority?
      const didPassSimpleMajority: boolean = offchainResult
        ? offchainResult.Yes.units > offchainResult.No.units
        : false;

      /**
       * Voting proposal: voting has ended, off-chain result was not submitted,
       * and there are votes with a passing result (need to submit to get true
       * "passed" result).
       *
       * @note For now, we can assume across all adapters that if the vote did
       * not pass then the result does not need to be submitted (proposal would
       * fall back to "failed" logic).
       * @note Should be placed before "failed" logic.
       */
      if (
        offchainResultNotYetSubmitted &&
        noSnapshotVotes === false &&
        didPassSimpleMajority
      ) {
        filteredProposalsToSet.votingProposals.push(p);

        return;
      }

      // Failed proposal
      if (
        voteState !== undefined &&
        (proposalHasVotingState(VotingState.NOT_PASS, voteState) ||
          proposalHasVotingState(VotingState.TIE, voteState)) &&
        (proposalHasFlag(ProposalFlag.SPONSORED, daoProposal.flags) ||
          proposalHasFlag(ProposalFlag.PROCESSED, daoProposal.flags))
      ) {
        filteredProposalsToSet.failedProposals.push(p);

        return;
      }

      // Failed proposal: no Snapshot votes
      if (
        voteState !== undefined &&
        offchainResultNotYetSubmitted &&
        noSnapshotVotes
      ) {
        filteredProposalsToSet.failedProposals.push(p);

        return;
      }

      // Failed proposal: result not submitted; vote did not pass
      if (
        voteState !== undefined &&
        offchainResultNotYetSubmitted &&
        !didPassSimpleMajority
      ) {
        filteredProposalsToSet.failedProposals.push(p);

        return;
      }

      // Voting proposal
      if (
        voteState !== undefined &&
        (proposalHasVotingState(VotingState.GRACE_PERIOD, voteState) ||
          proposalHasVotingState(VotingState.IN_PROGRESS, voteState)) &&
        proposalHasFlag(ProposalFlag.SPONSORED, daoProposal.flags)
      ) {
        filteredProposalsToSet.votingProposals.push(p);

        return;
      }
    });

    setFilteredProposals((prevState) => ({
      ...prevState,
      ...filteredProposalsToSet,
    }));
  }, [offchainVotingResults, proposals, proposalsStatus]);

  /**
   * Functions
   */

  function getUnauthorizedMessage() {
    // user is not connected
    if (!isConnected) {
      return 'Connect your wallet to submit a tribute proposal.';
    }

    // user is on wrong network
    if (defaultChainError) {
      return defaultChainError.message;
    }
  }

  // Checks if NFT is already part of a pending or passed proposal
  function isDuplicateProposal(): (() => React.ReactElement) | undefined {
    if (!selectedNFT) return;

    const pendingOrPassedProposals: ProposalData[] = [
      ...nonsponsoredProposals,
      ...passedProposals,
      ...votingProposals,
    ];

    const duplicateProposal = pendingOrPassedProposals.find((proposal) => {
      const tokenAddress =
        proposal.snapshotDraft?.msg.payload.metadata.tokenAddress ||
        proposal.snapshotProposal?.msg.payload.metadata.tokenAddress;
      const tokenId =
        proposal.snapshotDraft?.msg.payload.metadata.tokenId ||
        proposal.snapshotProposal?.msg.payload.metadata.tokenId;

      return (
        normalizeString(selectedNFT.address) ===
          normalizeString(tokenAddress) &&
        normalizeString(selectedNFT.tokenId) === normalizeString(tokenId)
      );
    });

    if (duplicateProposal) {
      const proposalId =
        duplicateProposal.snapshotDraft?.idInDAO ||
        duplicateProposal.snapshotProposal?.idInDAO;

      return () => (
        <>
          There is already a pending or accepted{' '}
          <Link
            to={`/curation/${proposalId}`}
            target="_blank"
            rel="noopener noreferrer">
            submission
          </Link>{' '}
          for this NFT.
        </>
      );
    } else {
      return undefined;
    }
  }

  async function handleSubmit(values: FormInputs) {
    try {
      // Don't submit if invalid NFT
      if (!selectedNFT) {
        throw new Error('You must select an NFT above.');
      }

      // Don't submit if user is not connected
      if (!account) {
        throw new Error(
          'No user account was found. Please make sure your wallet is connected.'
        );
      }

      // Don't submit if user does not own NFT
      if (account.toLowerCase() !== selectedNFT.owner.toLowerCase()) {
        throw new Error('Your connected account does not own the NFT.');
      }

      // Don't submit if waiting on check if NFT is already in DAO collection
      // (owned by the NFT extension contract or the `DAO_NFT_ADDITIONAL_OWNER`)
      if (
        isCollectedNFTStatus === AsyncStatus.STANDBY ||
        isCollectedNFTStatus === AsyncStatus.PENDING ||
        (DAO_NFT_ADDITIONAL_OWNER &&
          daoOwnedNFTsStatus === AsyncStatus.STANDBY) ||
        daoOwnedNFTsStatus === AsyncStatus.PENDING
      ) {
        return;
      }

      // Don't submit if NFT ownership could not be checked in DAO collection
      if (checkNFTOwnerError) {
        throw new Error(
          `Failure checking if NFT is already in DAO collection: ${checkNFTOwnerError.message}`
        );
      }

      // Don't submit if NFT has already been transferred to (1) the DAO
      // collection (the NFT extension contract) or (2) the
      // `DAO_NFT_ADDITIONAL_OWNER`
      if (
        (isCollectedNFTStatus === AsyncStatus.FULFILLED && isCollectedNFT) ||
        isOwnedByDaoNFTAdditionalOwner(selectedNFT)
      ) {
        setSubmitError(() => () => (
          <>
            The DAO collection already includes this{' '}
            <Link
              to={`/collection?address=${toChecksumAddress(
                selectedNFT.address
              )}&tokenId=${selectedNFT.tokenId}`}
              target="_blank"
              rel="noopener noreferrer">
              NFT
            </Link>
            .
          </>
        ));

        return;
      }

      // Don't submit if waiting on check if NFT is already part of a pending or passed proposal
      if (
        proposalsStatus === AsyncStatus.STANDBY ||
        proposalsStatus === AsyncStatus.PENDING ||
        (offchainVotingResultsStatus === AsyncStatus.STANDBY &&
          proposalsForVotingResults.length > 0) ||
        offchainVotingResultsStatus === AsyncStatus.PENDING
      ) {
        return;
      }

      // Don't submit if NFT could not be checked against existing proposals
      if (checkDuplicateProposalError) {
        throw new Error(
          `Failure checking if NFT is already part of a pending or accepted submission: ${checkDuplicateProposalError.message}`
        );
      }

      // Don't submit if NFT is already part of a pending or passed proposal
      if (
        proposalsStatus === AsyncStatus.FULFILLED &&
        !!isDuplicateProposal()
      ) {
        setSubmitError(isDuplicateProposal);

        return;
      }

      if (checkApplicantError) {
        // Just log the error (don't throw) because it is not a blocker for the
        // snapshot draft to be submitted. The applicant address validity will
        // be checked again when the proposal is submitted onchain.
        console.warn(
          `Error checking if the applicant address is valid: ${checkApplicantError.message}`
        );
      }

      // Don't submit if applicant address is invalid
      if (
        checkApplicantStatus === AsyncStatus.FULFILLED &&
        !isApplicantValid &&
        checkApplicantInvalidMsg
      ) {
        throw new Error(checkApplicantInvalidMsg);
      }

      // Maybe set proposal ID from previous attempt
      let proposalId: string = proposalData?.uniqueId || '';

      // Only submit to snapshot if there is not already a proposal ID returned from a previous attempt.
      if (!proposalId) {
        const {applicantAddress, emailAddress, description} = values;
        const applicantAddressToChecksum = toChecksumAddress(applicantAddress);
        const proposerAddressToChecksum = toChecksumAddress(account);
        const selectedNFTAddressToChecksum = toChecksumAddress(
          selectedNFT.address
        );

        const bodyIntro =
          normalizeString(applicantAddress) === normalizeString(account)
            ? `Tribute from ${truncateEthAddress(
                applicantAddressToChecksum,
                7
              )}.`
            : `Tribute from ${truncateEthAddress(
                proposerAddressToChecksum,
                7
              )} for applicant ${truncateEthAddress(
                applicantAddressToChecksum,
                7
              )}.`;
        const body = description.trim()
          ? `${bodyIntro}\n\n${description}`
          : bodyIntro;

        // Arguments needed to submit the proposal onchain are set in the
        // snapshot draft metadata.
        const submitActionArgs: SubmitActionArguments = [
          applicantAddressToChecksum,
          selectedNFTAddressToChecksum,
          selectedNFT.tokenId,
          // amount of DAO units issued for each passed proposal
          '10000',
        ];

        // Sign and submit draft for snapshot-hub
        const {uniqueId} = await signAndSendProposal({
          partialProposalData: {
            name: selectedNFT.tokenURIName || PLACEHOLDER,
            body,
            metadata: {
              submitActionArgs,
              accountAuthorizedToProcessPassedProposal:
                proposerAddressToChecksum,
              tokenAddress: toChecksumAddress(selectedNFT.address),
              tokenId: selectedNFT.tokenId,
              emailAddress,
              applicant: applicantAddressToChecksum,
            },
          },
          adapterName: ContractAdapterNames.tribute_nft,
          type: SnapshotType.draft,
        });

        proposalId = uniqueId;
      }

      // go to NFTTributeDetails page for newly created NFT tribute proposal
      history.push(`/curation/${proposalId}`);
    } catch (error) {
      setSubmitError(error);
    }
  }

  function renderSubmitStatus(): React.ReactNode {
    switch (proposalSignAndSendStatus) {
      case Web3TxStatus.AWAITING_CONFIRM:
        return (
          <>
            Awaiting your confirmation
            <CycleEllipsis intervalMs={500} />
          </>
        );
      case Web3TxStatus.PENDING:
        return (
          <>
            Submitting
            <CycleEllipsis intervalMs={500} />
          </>
        );
      case Web3TxStatus.FULFILLED:
        return 'Done!';
      default:
        return '';
    }
  }

  function handleClickSelectedNFT(nft: FetchedNFT) {
    setSelectedNFT(nft);
  }

  function handleClearSelectedNFT() {
    setSelectedNFT(undefined);
  }

  function renderPlaceholderNFT(): JSX.Element {
    return (
      <div className="nft-preview">
        <div className="nft-preview__image-column">
          <div className="nft-preview__image-container">
            <div className="thumb">
              <ImageSVG
                color="rgba(147, 147, 147, 0.2)"
                width="130px"
                height="130px"
              />
            </div>
          </div>
        </div>
        <div className="nft-preview__info-column">
          <button
            className="form__input-row-button"
            onClick={() => setShowOwnedNFTsModal(true)}
            disabled={isInProcessOrDone}>
            Select NFT
          </button>
        </div>
      </div>
    );
  }

  function isOwnedByDaoNFTAdditionalOwner(nftToCheck: FetchedNFT): boolean {
    if (!DAO_NFT_ADDITIONAL_OWNER) return false;

    return (
      nftToCheck.owner.toLowerCase() ===
        DAO_NFT_ADDITIONAL_OWNER.toLowerCase() ||
      (daoOwnedNFTsStatus === AsyncStatus.FULFILLED &&
        daoOwnedNFTs.some(
          (daoOwnedNFT) =>
            daoOwnedNFT.address.toLowerCase() ===
              nftToCheck.address.toLowerCase() &&
            daoOwnedNFT.tokenId.toLowerCase() ===
              nftToCheck.tokenId.toLowerCase()
        ))
    );
  }

  /**
   * Render
   */

  // Render unauthorized message
  if (!isConnected || defaultChainError) {
    return (
      <RenderWrapper>
        <div className="form__description--unauthorized">
          <p>{getUnauthorizedMessage()}</p>
        </div>
      </RenderWrapper>
    );
  }

  return (
    <RenderWrapper>
      <>
        <form className="form" onSubmit={(e) => e.preventDefault()}>
          {/* NFT */}
          <div className="form__textarea-row">
            {selectedNFT ? (
              <NFTPreview
                nft={selectedNFT}
                clearNFTHandler={handleClearSelectedNFT}
                clearNFTHandlerDisabled={isInProcessOrDone}
              />
            ) : (
              renderPlaceholderNFT()
            )}
          </div>

          {/* APPLICANT ADDRESS */}
          <div className="form__input-row">
            <label className="form__input-row-label">ETH Address</label>
            <div className="form__input-row-fieldwrap">
              {/* @note We don't need the default value as it's handled in the useEffect above. */}
              <input
                aria-describedby={`error-${Fields.applicantAddress}`}
                aria-invalid={errors.applicantAddress ? 'true' : 'false'}
                name={Fields.applicantAddress}
                ref={register({
                  validate: (applicantAddress: string): string | boolean => {
                    return !applicantAddress
                      ? FormFieldErrors.REQUIRED
                      : !isEthAddressValid(applicantAddress)
                      ? FormFieldErrors.INVALID_ETHEREUM_ADDRESS
                      : true;
                  },
                })}
                type="text"
                disabled={isInProcessOrDone}
              />

              <InputError
                error={getValidationError(Fields.applicantAddress, errors)}
                id={`error-${Fields.applicantAddress}`}
              />

              <div className="form__input-description">
                This is the address that will receive DAO membership tokens if
                accepted.
              </div>
            </div>
          </div>

          {/* EMAIL ADDRESS */}
          <div className="form__input-row">
            <label
              className="form__input-row-label"
              htmlFor={Fields.emailAddress}>
              Email <small>(optional)</small>
            </label>
            <div className="form__input-row-fieldwrap">
              <input
                aria-describedby={`error-${Fields.emailAddress}`}
                aria-invalid={errors.emailAddress ? 'true' : 'false'}
                id={Fields.emailAddress}
                name={Fields.emailAddress}
                ref={register({
                  validate: (emailAddress: string): string | boolean => {
                    return !emailAddress
                      ? true
                      : !isEmailValid(emailAddress)
                      ? FormFieldErrors.INVALID_EMAIL
                      : true;
                  },
                })}
                type="text"
                disabled={isInProcessOrDone}
              />

              <InputError
                error={getValidationError(Fields.emailAddress, errors)}
                id={`error-${Fields.emailAddress}`}
              />

              <div className="form__input-description">
                Provide a contact email if you would like to be notified when
                voting on your submission has completed. Otherwise you should
                periodically check the status on the "curate" page.{' '}
                <span style={{fontStyle: 'italic', fontWeight: 'bold'}}>
                  If your submission is accepted, only you can process the NFT
                  transfer.
                </span>
              </div>
            </div>
          </div>

          {/* PROPOSAL DESCRIPTION */}
          <div className="form__textarea-row">
            <label
              className="form__input-row-label"
              htmlFor={Fields.description}>
              Description
            </label>
            <div className="form__input-row-fieldwrap">
              <textarea
                aria-describedby={`error-${Fields.description}`}
                aria-invalid={errors.description ? 'true' : 'false'}
                id={Fields.description}
                name={Fields.description}
                placeholder="Say something about your NFT..."
                ref={register({
                  required: FormFieldErrors.REQUIRED,
                })}
                disabled={isInProcessOrDone}
              />

              <InputError
                error={getValidationError(Fields.description, errors)}
                id={`error-${Fields.description}`}
              />

              <div
                className="form__input-description"
                style={{marginBottom: '0.5rem'}}>
                Provide information that would help the members better evaluate
                your NFT (e.g., artist info, creation process, any collection
                the NFT is part of). Feel free to include links in the
                description.
              </div>

              <PreviewInputMarkdown value={watch(Fields.description)} />
            </div>
          </div>

          {/* ACCEPT TERMS */}
          <div className="form__input-row">
            <label className="form__input-row-label"></label>
            <div className="form__input-row-fieldwrap">
              <input
                className="checkbox-input"
                aria-describedby={`error-${Fields.termsAccept}`}
                aria-invalid={errors.termsAccept ? 'true' : 'false'}
                id={Fields.termsAccept}
                name={Fields.termsAccept}
                ref={register({
                  required: FormFieldErrors.REQUIRED,
                })}
                type="checkbox"
                disabled={isInProcessOrDone}
              />

              <label className="checkbox-label" htmlFor={Fields.termsAccept}>
                <span className={`checkbox-box ${CheckboxSize.SMALL}`}></span>
                <span className="checkbox-text">
                  I have read and agree to the{' '}
                  <button
                    className="checkbox-text__button"
                    onClick={() => setShowTermsModal(true)}>
                    terms
                  </button>{' '}
                  of transferring my NFT to <Muse0Text /> and becoming a member
                  of the DAO if my submission is accepted.
                  <br />
                  <br />
                  <span style={{fontStyle: 'italic', fontWeight: 'bold'}}>
                    Your NFT will NOT be transferred upon submitting this form.
                  </span>{' '}
                  If your submission is accepted, you will then be contacted to
                  process the transfer.
                </span>
              </label>

              <InputError
                error={getValidationError(Fields.termsAccept, errors)}
                id={`error-${Fields.termsAccept}`}
              />
            </div>
          </div>

          {/* SUBMIT */}
          <button
            aria-label={isInProcess ? 'Submitting your proposal...' : ''}
            className="button"
            disabled={isInProcessOrDone}
            onClick={async () => {
              if (isInProcessOrDone) return;

              if (!(await trigger())) {
                return;
              }

              handleSubmit(getValues());
            }}
            type="submit">
            {isInProcess ? <Loader /> : isDone ? 'Done' : 'Submit'}
          </button>

          {/* SUBMIT STATUS */}
          {isInProcessOrDone && (
            <div className="form__submit-status-container">
              {renderSubmitStatus()}
            </div>
          )}

          {/* SUBMIT ERROR */}
          {createNFTTributeError && (
            <div className="form__submit-error-container">
              <ErrorMessageWithDetails
                renderText="Something went wrong with the submission."
                error={createNFTTributeError}
              />
            </div>
          )}
        </form>

        {/* OWNED NFTS MODAL */}
        {showOwnedNFTsModal && (
          <OwnedNFTsModal
            isOpen={showOwnedNFTsModal}
            closeHandler={() => {
              setShowOwnedNFTsModal(false);
            }}
            selectNFTHandler={handleClickSelectedNFT}
            owner={account}
          />
        )}

        {/* TERMS MODAL */}
        {showTermsModal && (
          <NFTMemberTermsModal
            isOpen={showTermsModal}
            closeHandler={() => {
              setShowTermsModal(false);
            }}
          />
        )}
      </>
    </RenderWrapper>
  );
}

function RenderWrapper(props: React.PropsWithChildren<any>): JSX.Element {
  return (
    <Wrap className="section-wrapper">
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title">Donate</h2>
        </div>

        <div className="form-wrapper">
          <div className="form__description">
            <p>
              <Muse0Text /> is a DAO which operates as a digitally native museum
              with curated, beautiful NFTs, run entirely by its members. If you
              would like to donate an NFT to join, fill out the form below!
              You'll be asked to choose an NFT and provide some basic
              information about the work.{' '}
              <span style={{fontStyle: 'italic', fontWeight: 'bold'}}>
                Submitting this form will NOT actually transfer your NFT.
              </span>
            </p>
            <p>
              Following your submission, existing <Muse0Text /> members vote on
              whether to accept your work into the collection. If your work is
              put up to vote and receives more "yes" votes than "no" votes
              during the <span style={{whiteSpace: 'nowrap'}}>3-day</span>{' '}
              voting period, after an additional{' '}
              <span style={{whiteSpace: 'nowrap'}}>1-day</span> grace period,
              you will then be able to transfer the NFT to the DAO.{' '}
              <small>
                (Most NFTs are currently supported for automated transfer with
                just a few clicks. If your NFT is not supported, you will
                receive further instructions on how to complete the transfer.)
              </small>{' '}
              Once the NFT is received by the DAO, you'll receive an allocation
              of <Muse0Text /> governance tokens and can help curate the
              collection going forward.
            </p>
          </div>

          {/* RENDER CHILDREN */}
          {props.children}
        </div>
      </FadeIn>
    </Wrap>
  );
}
