import {useState, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLazyQuery} from '@apollo/react-hooks';
import usePreviousDistinct from 'react-use/lib/usePreviousDistinct';

import {GUILD_ADDRESS} from '../../../config';
import {AsyncStatus} from '../../../util/types';
import {StoreState} from '../../../store/types';
import {SubgraphNetworkStatus} from '../../../store/subgraphNetworkStatus/types';
import {GET_COLLECTED_NFT} from '../../../gql';

type UseIsCollectedNFT = {
  isCollectedNFT: boolean;
  isCollectedNFTStatus: AsyncStatus;
  isCollectedNFTError: Error | undefined;
};

/**
 * useIsCollectedNFT
 *
 * Checks if NFT is in DAO collection (has been collected into the NFT extension contract).
 *
 * @param {string} [tokenAddress]
 * @param {string} [tokenId]
 * @returns {UseIsCollectedNFT}
 */
export function useIsCollectedNFT(
  tokenAddress?: string,
  tokenId?: string
): UseIsCollectedNFT {
  /**
   * Selectors
   */

  const DaoRegistryContract = useSelector(
    (state: StoreState) => state.contracts.DaoRegistryContract
  );
  const NFTExtensionContract = useSelector(
    (state: StoreState) => state.contracts.NFTExtensionContract
  );
  const subgraphNetworkStatus = useSelector(
    (state: StoreState) => state.subgraphNetworkStatus.status
  );

  /**
   * GQL Query
   */

  const [getCollectedNFTFromSubgraphResult, {called, loading, data, error}] =
    useLazyQuery(GET_COLLECTED_NFT, {
      variables: {
        daoAddress: DaoRegistryContract?.contractAddress.toLowerCase(),
        nftAddress: tokenAddress?.toLowerCase(),
        tokenId: tokenId?.toLowerCase(),
      },
    });

  /**
   * State
   */

  const [isCollectedNFT, setIsCollectedNFT] = useState<boolean>(false);
  const [isCollectedNFTStatus, setIsCollectedNFTStatus] = useState<AsyncStatus>(
    AsyncStatus.STANDBY
  );
  const [isCollectedNFTError, setIsCollectedNFTError] = useState<Error>();

  /**
   * Their hooks
   */

  const prevTokenAddress = usePreviousDistinct(tokenAddress);
  const prevTokenId = usePreviousDistinct(tokenId);

  /**
   * Cached callbacks
   */

  const checkNFTOwnerFromExtensionCached = useCallback(
    checkNFTOwnerFromExtension,
    [NFTExtensionContract, tokenAddress, tokenId]
  );
  const getCollectedNFTFromSubgraphCached = useCallback(
    getCollectedNFTFromSubgraph,
    [checkNFTOwnerFromExtensionCached, data, error]
  );

  /**
   * Effects
   */

  useEffect(() => {
    if (!called) {
      getCollectedNFTFromSubgraphResult();
    }
  }, [called, getCollectedNFTFromSubgraphResult]);

  useEffect(() => {
    // Reset state if input value is cleared
    if ((prevTokenAddress && !tokenAddress) || (prevTokenId && !tokenId)) {
      setIsCollectedNFT(false);
      setIsCollectedNFTError(undefined);
      setIsCollectedNFTStatus(AsyncStatus.STANDBY);
    }

    if (subgraphNetworkStatus === SubgraphNetworkStatus.OK) {
      if (
        called &&
        !loading &&
        DaoRegistryContract?.contractAddress &&
        tokenAddress &&
        tokenId
      ) {
        getCollectedNFTFromSubgraphCached();
      }
    } else {
      // If there is a subgraph network error fallback to fetching NFT directly
      // from smart contracts
      checkNFTOwnerFromExtensionCached();
    }
  }, [
    DaoRegistryContract?.contractAddress,
    called,
    checkNFTOwnerFromExtensionCached,
    getCollectedNFTFromSubgraphCached,
    loading,
    prevTokenAddress,
    prevTokenId,
    subgraphNetworkStatus,
    tokenAddress,
    tokenId,
  ]);

  /**
   * Functions
   */

  function getCollectedNFTFromSubgraph() {
    try {
      setIsCollectedNFTStatus(AsyncStatus.PENDING);

      if (data) {
        // extract NFTs from gql data
        const {
          nftCollection: {nfts},
        } = data.tributeDaos[0] as Record<string, any>;

        if (!nfts) {
          throw new Error('nfts are undefined');
        } else {
          setIsCollectedNFT(nfts.length > 0);
          setIsCollectedNFTStatus(AsyncStatus.FULFILLED);
        }
      } else {
        if (error) {
          throw new Error(`subgraph query error: ${error.message}`);
        } else if (typeof data === 'undefined') {
          // Additional case to catch `{"errors":{"message":"No indexers found
          // for subgraph deployment"}}` which does not get returned as an error
          // by the graph query call.
          throw new Error('subgraph query error: data is undefined');
        }
      }
    } catch (error) {
      const {message} = error as Error;

      // If there is a subgraph query error fallback to fetching NFT directly
      // from smart contracts
      console.log(message);

      checkNFTOwnerFromExtensionCached();
    }
  }

  async function checkNFTOwnerFromExtension() {
    if (!NFTExtensionContract || !tokenAddress || !tokenId) {
      return;
    }

    try {
      setIsCollectedNFTStatus(AsyncStatus.PENDING);

      const {
        instance: {methods: nftExtensionMethods},
      } = NFTExtensionContract;

      const nftOwner = await nftExtensionMethods
        .getNFTOwner(tokenAddress, tokenId)
        .call();

      setIsCollectedNFT(nftOwner.toLowerCase() === GUILD_ADDRESS.toLowerCase());
      setIsCollectedNFTStatus(AsyncStatus.FULFILLED);
    } catch (error) {
      setIsCollectedNFT(false);
      setIsCollectedNFTError(error);
      setIsCollectedNFTStatus(AsyncStatus.REJECTED);
    }
  }

  return {isCollectedNFT, isCollectedNFTError, isCollectedNFTStatus};
}
