import {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {toChecksumAddress} from 'web3-utils';

import {
  RenderActionPropArguments,
  ProposalFlowStatus,
} from '../../../components/proposals/types';
import {AsyncStatus} from '../../../util/types';
import {ContractAdapterNames} from '../../../components/web3/types';
import {useIsDefaultChain} from '../../../components/web3/hooks';
import {useProposalOrDraft} from '../../../components/proposals/hooks';
import {VotingState} from '../../../components/proposals/voting/types';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../../components/common/FadeIn';
import LoaderLarge from '../../../components/feedback/LoaderLarge';
import NotFound from '../../subpages/NotFound';
import ProcessActionNFTTribute from '../../../components/nfts/ProcessActionNFTTribute';
import {useIsCollectedNFT} from '../../../components/nfts/hooks';
import ProposalActions from '../../../components/proposals/ProposalActions';
import SubmitAction from '../../../components/proposals/SubmitAction';
import NFTTributeProposalDetails from '../../../components/nfts/NFTTributeProposalDetails';
import {useNFTAsset, useNFTAssetsByOwner} from '../../../components/nfts/hooks';
import {FetchedNFT} from '../../../components/nfts/types';
import {DAO_NFT_ADDITIONAL_OWNER} from '../../../config';
import Wrap from '../../../components/common/Wrap';

export default function NFTTributeDetails() {
  /**
   * State
   */

  const [nftInfoFromMetadata, setNFTInfoFromMetadata] = useState<{
    tokenAddress: string;
    tokenId: string;
  }>({tokenAddress: '', tokenId: ''});

  /**
   * Their hooks
   */

  // Get hash for fetching the proposal.
  const {proposalId} = useParams<{proposalId: string}>();

  /**
   * Our hooks
   */

  const {proposalData, proposalError, proposalNotFound, proposalStatus} =
    useProposalOrDraft(proposalId);

  const {isCollectedNFT, isCollectedNFTStatus} = useIsCollectedNFT(
    nftInfoFromMetadata.tokenAddress,
    nftInfoFromMetadata.tokenId
  );

  const nftAssetReturn = useNFTAsset(
    nftInfoFromMetadata.tokenAddress,
    nftInfoFromMetadata.tokenId
  );

  const {ownedNFTs: daoOwnedNFTs, ownedNFTsStatus: daoOwnedNFTsStatus} =
    useNFTAssetsByOwner(DAO_NFT_ADDITIONAL_OWNER);

  const {defaultChainError} = useIsDefaultChain();

  /**
   * Variables
   */

  const isLoading: boolean =
    proposalStatus === AsyncStatus.STANDBY ||
    proposalStatus === AsyncStatus.PENDING ||
    isCollectedNFTStatus === AsyncStatus.STANDBY ||
    isCollectedNFTStatus === AsyncStatus.PENDING ||
    (DAO_NFT_ADDITIONAL_OWNER && daoOwnedNFTsStatus === AsyncStatus.STANDBY) ||
    daoOwnedNFTsStatus === AsyncStatus.PENDING;

  const error: Error | undefined = proposalError || defaultChainError;

  const commonData = proposalData?.getCommonSnapshotProposalData();

  const {nft} = nftAssetReturn;

  /**
   * Effects
   */

  useEffect(() => {
    setNFTInfoFromMetadata({
      tokenAddress: commonData?.msg.payload.metadata.tokenAddress || '',
      tokenId: commonData?.msg.payload.metadata.tokenId || '',
    });
  }, [
    commonData?.msg.payload.metadata.tokenAddress,
    commonData?.msg.payload.metadata.tokenId,
  ]);

  /**
   * Functions
   */

  // Render any adapter-specific actions
  function renderAction(data: RenderActionPropArguments): React.ReactNode {
    const {
      OffchainVotingContract: {daoProposalVoteResult, proposal, status},
    } = data;

    // Submit/Sponsor button (for proposals that have not been submitted onchain
    // yet)
    if (status === ProposalFlowStatus.Submit) {
      const {snapshotDraft} = proposal;
      const applicant = snapshotDraft?.msg.payload.metadata.submitActionArgs[0];

      return <SubmitAction checkApplicant={applicant} proposal={proposal} />;
    }

    // Process (Transfer NFT) button
    if (
      status === ProposalFlowStatus.Process ||
      status === ProposalFlowStatus.OffchainVotingGracePeriod
    ) {
      // Return a React.Fragment to hide the process button if proposal failed.
      if (
        daoProposalVoteResult &&
        VotingState[daoProposalVoteResult] !== VotingState[VotingState.PASS]
      ) {
        return <></>;
      }

      // Proposal with non-ERC721 type NFT will not be processed. Show
      // confirmation message if the NFT has already been manually transferred
      // to the `DAO_NFT_ADDITIONAL_OWNER`.
      if (
        nft &&
        nft.schema !== 'ERC721' &&
        isOwnedByDaoNFTAdditionalOwner(nft)
      ) {
        return (
          <div style={{textAlign: 'center'}}>
            The NFT has been transferred to the{' '}
            <Link
              to={`/collection?address=${toChecksumAddress(
                nft.address
              )}&tokenId=${nft.tokenId}`}
              target="_blank"
              rel="noopener noreferrer">
              collection
            </Link>
            .
          </div>
        );
      }

      // If proposal with ERC721 type NFT cannot be processed (most likely due
      // to issue with NFT) then we will try to manually transfer the NFT to the
      // NFT extension contract. In some cases, the NFT may have been manually
      // transferred to the `DAO_NFT_ADDITIONAL_OWNER` instead. Show
      // confirmation message if the NFT has already been transferred to the NFT
      // extension contract or the `DAO_NFT_ADDITIONAL_OWNER`.
      if (
        nft?.schema === 'ERC721' &&
        ((isCollectedNFTStatus === AsyncStatus.FULFILLED && isCollectedNFT) ||
          isOwnedByDaoNFTAdditionalOwner(nft))
      ) {
        return (
          <div style={{textAlign: 'center'}}>
            The NFT has been transferred to the{' '}
            <Link
              to={`/collection?address=${toChecksumAddress(
                nft.address
              )}&tokenId=${nft.tokenId}`}
              target="_blank"
              rel="noopener noreferrer">
              collection
            </Link>
            .
          </div>
        );
      }

      return (
        <ProcessActionNFTTribute
          // Show during DAO proposal grace period, but set to disabled
          disabled={status === ProposalFlowStatus.OffchainVotingGracePeriod}
          proposal={proposal}
          nft={nft}
        />
      );
    }

    // Show confirmation message if the passed proposal has been processed (the
    // NFT has been automatically transferred to the NFT extension contract).
    if (
      nft?.schema === 'ERC721' &&
      status === ProposalFlowStatus.Completed &&
      daoProposalVoteResult &&
      VotingState[daoProposalVoteResult] === VotingState[VotingState.PASS]
    ) {
      return (
        <div style={{textAlign: 'center'}}>
          The NFT has been transferred to the{' '}
          <Link
            to={`/collection?address=${toChecksumAddress(
              nft.address
            )}&tokenId=${nft.tokenId}`}
            target="_blank"
            rel="noopener noreferrer">
            collection
          </Link>
          .
        </div>
      );
    }

    // Return `null` to signal to use default actions
    return null;
  }

  function isOwnedByDaoNFTAdditionalOwner(nftToCheck: FetchedNFT): boolean {
    if (!DAO_NFT_ADDITIONAL_OWNER) return false;

    return (
      nftToCheck.owner.toLowerCase() ===
        DAO_NFT_ADDITIONAL_OWNER.toLowerCase() ||
      (daoOwnedNFTsStatus === AsyncStatus.FULFILLED &&
        daoOwnedNFTs.some(
          (daoOwnedNFT) =>
            daoOwnedNFT.address.toLowerCase() ===
              nftToCheck.address.toLowerCase() &&
            daoOwnedNFT.tokenId.toLowerCase() ===
              nftToCheck.tokenId.toLowerCase()
        ))
    );
  }

  /**
   * Render
   */

  // Render loading
  if (isLoading && !error) {
    return (
      <RenderWrapper>
        <div className="loader--large-container">
          <LoaderLarge />
        </div>
      </RenderWrapper>
    );
  }

  // Render 404 no proposal found
  if (proposalNotFound) {
    return (
      <RenderWrapper>
        <NotFound />
      </RenderWrapper>
    );
  }

  // Render error
  if (error) {
    return (
      <RenderWrapper>
        <div className="text-center">
          <ErrorMessageWithDetails
            error={error}
            renderText="Something went wrong."
          />
        </div>
      </RenderWrapper>
    );
  }

  // Render proposal
  if (proposalData) {
    return (
      <RenderWrapper>
        <NFTTributeProposalDetails
          proposal={proposalData}
          renderActions={() => (
            <ProposalActions
              adapterName={ContractAdapterNames.tribute_nft}
              proposal={proposalData}
              renderAction={renderAction}
            />
          )}
          nftAssetInfo={nftAssetReturn}
        />
      </RenderWrapper>
    );
  }

  // Render nothing. Should never reach this case.
  return <></>;
}

function RenderWrapper(props: React.PropsWithChildren<any>): JSX.Element {
  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * Functions
   */

  function viewAll(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    history.push('/curation');
  }

  /**
   * Render
   */

  return (
    <Wrap className="section-wrapper">
      <FadeIn>
        <div className="titlebar">
          <div className="titlebar__title-container">
            <h2 className="titlebar__title">Curate</h2>
            <div className="titlebar__subtitle">
              <p>
                Curation is handled by existing members who choose which NFTs to
                accept. Accepted artists participate in subsequent curation.
              </p>
            </div>
          </div>
          <button className="titlebar__action" onClick={viewAll}>
            View all
          </button>
        </div>

        {/* RENDER CHILDREN */}
        {props.children}
      </FadeIn>
    </Wrap>
  );
}
