import {useState, useCallback, useEffect} from 'react';
import {toChecksumAddress} from 'web3-utils';
import usePreviousDistinct from 'react-use/lib/usePreviousDistinct';
import {useQuery} from 'react-query';

import {FetchedNFT} from '../types';
import {getNFTAsset} from '../helpers';
import {AsyncStatus} from '../../../util/types';

export type UseNFTAssetReturn = {
  nft: FetchedNFT | undefined;
  nftStatus: AsyncStatus;
  nftError: Error | undefined;
};

export const NFT_QUERY_KEY: string = 'NFT_QUERY_KEY';

/**
 * useNFTAsset
 *
 * Gets NFT asset by tokenAddress and tokenId.
 *
 * @param {string} tokenAddress
 * @param {string} tokenId
 * @returns {UseNFTAssetReturn} An object with the NFT asset data and the current async status.
 */
export function useNFTAsset(
  tokenAddress: string,
  tokenId: string
): UseNFTAssetReturn {
  /**
   * State
   */

  const [nft, setNFT] = useState<FetchedNFT>();
  const [nftStatus, setNFTStatus] = useState<AsyncStatus>(AsyncStatus.STANDBY);
  const [nftError, setNFTError] = useState<Error>();

  /**
   * Their hooks
   */

  const prevTokenAddress = usePreviousDistinct(tokenAddress);
  const prevTokenId = usePreviousDistinct(tokenId);

  /**
   * React Query
   */

  const {data: nftAssetData, error: nftAssetError} = useQuery(
    [NFT_QUERY_KEY, 'nftAsset', tokenAddress, tokenId],
    async () => await getNFTAsset(tokenAddress, tokenId),
    {enabled: !!tokenAddress && !!tokenId}
  );

  /**
   * Cached callbacks
   */

  const getNFTDataCached = useCallback(getNFTData, [
    nftAssetData,
    nftAssetError,
    tokenAddress,
    tokenId,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    // Reset state if input value is cleared
    if ((prevTokenAddress && !tokenAddress) || (prevTokenId && !tokenId)) {
      setNFT(undefined);
      setNFTError(undefined);
      setNFTStatus(AsyncStatus.STANDBY);
    }

    if (tokenAddress && tokenId) {
      getNFTDataCached();
    }
  }, [getNFTDataCached, prevTokenAddress, prevTokenId, tokenAddress, tokenId]);

  /**
   * Functions
   */

  async function getNFTData() {
    try {
      setNFTStatus(AsyncStatus.PENDING);

      if (nftAssetError) {
        throw nftAssetError;
      }

      if (nftAssetData) {
        const {
          animation_url,
          asset_contract,
          description,
          external_link,
          image_data,
          image_url,
          name: tokenURIName,
          top_ownerships,
        } = nftAssetData;
        const {
          name: tokenName,
          schema_name: schema,
          symbol: tokenSymbol,
        } = asset_contract as any;
        const {address} = (top_ownerships as any)[0].owner;

        const nftData = {
          address: toChecksumAddress(tokenAddress),
          animation_url,
          description,
          external_link,
          image_data,
          image_url,
          owner: toChecksumAddress(address),
          schema,
          tokenId,
          tokenName,
          tokenSymbol,
          tokenURIName,
        };

        setNFT(nftData);
        setNFTError(undefined);
        setNFTStatus(AsyncStatus.FULFILLED);
      }
    } catch (error) {
      setNFT(undefined);
      setNFTError(error);
      setNFTStatus(AsyncStatus.REJECTED);
    }
  }

  return {nft, nftError, nftStatus};
}
