import {useState, useCallback, useEffect} from 'react';
import {toChecksumAddress} from 'web3-utils';
import {useQuery} from 'react-query';

import {FetchedNFT, NFTTokenData} from '../types';
import {getNFTAssets} from '../helpers';
import {AsyncStatus} from '../../../util/types';
import {NFT_QUERY_KEY} from './useNFTAsset';

type UseNFTAssetsReturn = {
  nfts: FetchedNFT[];
  nftsStatus: AsyncStatus;
  nftsError: Error | undefined;
};

/**
 * useNFTAssets
 *
 * Gets NFT assets by tokenAddresses and tokenIds.
 *
 * @param {NFTTokenData[]} nftTokenData
 * @returns {UseNFTAssetsReturn} An object with the NFT assets data and the current async status.
 */
export function useNFTAssets(nftTokenData: NFTTokenData[]): UseNFTAssetsReturn {
  /**
   * State
   */

  const [nfts, setNFTs] = useState<FetchedNFT[]>([]);
  const [nftsStatus, setNFTsStatus] = useState<AsyncStatus>(
    AsyncStatus.STANDBY
  );
  const [nftsError, setNFTsError] = useState<Error>();

  /**
   * React Query
   */

  const {data: nftAssetsData, error: nftAssetsError} = useQuery(
    [NFT_QUERY_KEY, 'nftAssets', nftTokenData],
    async () =>
      await getNFTAssets(
        nftTokenData.map((token) => token.address),
        nftTokenData.map((token) => token.tokenId)
      ),
    {
      enabled: !!nftTokenData.length,
    }
  );

  /**
   * Cached callbacks
   */

  const getNFTsDataCached = useCallback(getNFTsData, [
    nftAssetsData,
    nftAssetsError,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    if (nftTokenData.length > 0) {
      getNFTsDataCached();
    }
  }, [getNFTsDataCached, nftTokenData.length]);

  /**
   * Functions
   */

  async function getNFTsData() {
    try {
      setNFTsStatus(AsyncStatus.PENDING);

      if (nftAssetsError) {
        throw nftAssetsError;
      }

      if (nftAssetsData) {
        // Will return a list of assets that match each (tokenAddress, tokenId)
        // pairing, respecting order in the 2 arrays.
        const fetchedNFTs = nftAssetsData;

        const nftsData = fetchedNFTs
          .map((asset: any) => {
            const {
              animation_url,
              asset_contract,
              description,
              external_link,
              image_data,
              image_url,
              name: tokenURIName,
              owner,
              token_id,
            } = asset;
            const {
              address: tokenAddress,
              name: tokenName,
              schema_name: schema,
              symbol: tokenSymbol,
            } = asset_contract;
            const {address: ownerAddress} = owner;

            return {
              address: toChecksumAddress(tokenAddress),
              animation_url,
              description,
              external_link,
              image_data,
              image_url,
              owner: toChecksumAddress(ownerAddress),
              schema,
              tokenId: token_id,
              tokenName,
              tokenSymbol,
              tokenURIName,
            };
          })
          // Sort by NFT contract name (asc) then contract address (asc) then
          // tokenId (asc).
          .sort(
            (a, b) =>
              a.tokenName.localeCompare(b.tokenName) ||
              a.address.localeCompare(b.address) ||
              Number(a.tokenId) - Number(b.tokenId)
          );

        setNFTs(nftsData);
        setNFTsStatus(AsyncStatus.FULFILLED);
      }
    } catch (error) {
      setNFTs([]);
      setNFTsError(error);
      setNFTsStatus(AsyncStatus.REJECTED);
    }
  }

  return {nfts, nftsError, nftsStatus};
}
