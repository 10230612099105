import {config as dotenvConfig} from 'dotenv';
import {IProviderOptions} from 'web3modal';
import {isMobile} from '@walletconnect/browser-utils';
import {resolve} from 'path';
import WalletConnectProvider from '@walletconnect/web3-provider';

import {EnvironmentName} from './util/types';

dotenvConfig({path: resolve(__dirname, '../.env')});

/**
 * Global DApp Config
 */

const {
  REACT_APP_ADMIN_ACCOUNTS,
  REACT_APP_COUPON_API_URL,
  REACT_APP_DAO_NFT_ADDITIONAL_OWNER,
  REACT_APP_DAO_REGISTRY_CONTRACT_ADDRESS,
  REACT_APP_DEFAULT_CHAIN_NAME_LOCAL,
  REACT_APP_ENVIRONMENT,
  REACT_APP_GRAPH_API_URL,
  REACT_APP_INFURA_PROJECT_ID_DEV,
  REACT_APP_INFURA_PROJECT_ID_LOCAL,
  REACT_APP_INFURA_PROJECT_ID_PROD,
  REACT_APP_MULTICALL_CONTRACT_ADDRESS,
  REACT_APP_OPENSEA_API_KEY,
  REACT_APP_SNAPSHOT_HUB_API_URL,
  REACT_APP_SNAPSHOT_SPACE,
} = process.env;

export const ENVIRONMENT = REACT_APP_ENVIRONMENT as EnvironmentName | undefined;

/**
 * SNAPSHOT_HUB_API_URL
 *
 * @note For `ENVIRONMENT=localhost` we need to use CRA's local proxy
 *   so that we can communicate with our develop Snapshot Hub API
 *   without any CORS issues.
 *
 * @see src/setupProxy.js
 */
export const SNAPSHOT_HUB_API_URL: string | undefined =
  ENVIRONMENT === 'localhost'
    ? '/snapshot-hub'
    : REACT_APP_SNAPSHOT_HUB_API_URL;

// OpenSea URL
export const OPENSEA_ASSETS_URL: string =
  ENVIRONMENT === 'production'
    ? 'https://opensea.io/assets'
    : 'https://testnets.opensea.io/assets';

// OpenSea API URL
export const OPENSEA_API_URL: string =
  ENVIRONMENT === 'production'
    ? 'https://api.opensea.io/api/v1'
    : 'https://rinkeby-api.opensea.io/api/v1';

export const COUPON_API_URL: string | undefined = REACT_APP_COUPON_API_URL;

// The Graph API URL
export const GRAPH_API_URL = REACT_APP_GRAPH_API_URL;

// Network IDs, when users change wallet networks
export const CHAINS = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
  GANACHE: 1337,
  HARMONY_TEST: 1666700000,
  HARMONY_MAIN: 1666600000,
  POLYGON_TEST: 80001,
  POLYGON: 137,
} as const;

// Network names
export const CHAIN_NAME = {
  [CHAINS.MAINNET]: 'mainnet',
  [CHAINS.ROPSTEN]: 'ropsten',
  [CHAINS.RINKEBY]: 'rinkeby',
  [CHAINS.GOERLI]: 'goerli',
  [CHAINS.KOVAN]: 'kovan',
  [CHAINS.GANACHE]: 'ganache',
  [CHAINS.HARMONY_TEST]: 'harmony-testnet',
  [CHAINS.HARMONY_MAIN]: 'harmony-mainnet',
  [CHAINS.POLYGON]: 'polygon-mainnet',
  [CHAINS.POLYGON_TEST]: 'polygon-testnet',
} as const;

// Network names verbose
export const CHAIN_NAME_FULL = {
  [CHAINS.MAINNET]: 'Main Ethereum Network',
  [CHAINS.ROPSTEN]: 'Ropsten Test Network',
  [CHAINS.RINKEBY]: 'Rinkeby Test Network',
  [CHAINS.GOERLI]: 'Görli Test Network',
  [CHAINS.KOVAN]: 'Kovan Test Network',
  [CHAINS.GANACHE]: 'Ganache Test Network',
  [CHAINS.HARMONY_TEST]: 'Harmony Test Network',
  [CHAINS.HARMONY_MAIN]: 'Harmony Main Network',
  [CHAINS.POLYGON_TEST]: 'Polygon Test Network',
  [CHAINS.POLYGON]: 'Polygon Main Network',
};

export const DEFAULT_CHAIN: typeof CHAINS[keyof typeof CHAINS] =
  REACT_APP_ENVIRONMENT === 'production'
    ? CHAINS.MAINNET
    : REACT_APP_ENVIRONMENT === 'development'
    ? CHAINS.RINKEBY
    : REACT_APP_DEFAULT_CHAIN_NAME_LOCAL // Set this to change local development chain
    ? CHAINS[REACT_APP_DEFAULT_CHAIN_NAME_LOCAL]
    : CHAINS.GANACHE; // Defaults to a Ganache private network (1337)

export const ETHERSCAN_URLS: {[chainId: number]: string} = {
  [CHAINS.MAINNET]: `https://etherscan.io`,
  [CHAINS.ROPSTEN]: `https://ropsten.etherscan.io`,
  [CHAINS.RINKEBY]: `https://rinkeby.etherscan.io`,
  [CHAINS.GOERLI]: `https://goerli.etherscan.io`,
  [CHAINS.KOVAN]: `https://kovan.etherscan.io`,
  [CHAINS.HARMONY_TEST]: `https://explorer.pops.one`,
  [CHAINS.HARMONY_MAIN]: `https://explorer.harmony.one`,
  [CHAINS.POLYGON_TEST]: `https://mumbai.polygonscan.com`,
  [CHAINS.POLYGON]: `https://polygonscan.com`,
};

export const INFURA_WS_URLS: {[chainId: number]: string} = {
  [CHAINS.MAINNET]: `wss://mainnet.infura.io/ws/v3`,
  [CHAINS.ROPSTEN]: `wss://ropsten.infura.io/ws/v3`,
  [CHAINS.RINKEBY]: `wss://rinkeby.infura.io/ws/v3`,
  [CHAINS.GOERLI]: `wss://goerli.infura.io/ws/v3`,
  [CHAINS.KOVAN]: `wss://kovan.infura.io/ws/v3`,
  [CHAINS.HARMONY_TEST]: `wss://ws.s0.pops.one`,
  [CHAINS.HARMONY_MAIN]: `wss://ws.s0.t.hmny.io`,
  [CHAINS.POLYGON_TEST]: `wss://ws-matic-mumbai.chainstacklabs.com`,
  [CHAINS.POLYGON]: `wss://ws-matic-mainnet.chainstacklabs.com`,
};

// Infura Project Id
export const INFURA_PROJECT_ID =
  REACT_APP_ENVIRONMENT === 'production'
    ? REACT_APP_INFURA_PROJECT_ID_PROD
    : REACT_APP_ENVIRONMENT === 'development'
    ? REACT_APP_INFURA_PROJECT_ID_DEV
    : REACT_APP_INFURA_PROJECT_ID_LOCAL;

// Ethereum Provider URL
export const ETHEREUM_PROVIDER_URL: string = INFURA_WS_URLS[DEFAULT_CHAIN]
  ? `${INFURA_WS_URLS[DEFAULT_CHAIN]}/${INFURA_PROJECT_ID}`
  : DEFAULT_CHAIN === CHAINS.GANACHE
  ? /**
     * Ganache over WebSocket should work. @note Is not tested, yet.
     * Attempting to be consistent with a WebSocket URL to avoid more logic.
     *
     * @link https://www.trufflesuite.com/docs/truffle/reference/configuration#networks
     */
    'ws://127.0.0.1:7545'
  : '';

/**
 * Wallet Connect config
 */

/**
 * Tell Web3modal what providers we have available.
 *
 * The built-in web browser provider (only one can exist at a time),
 * MetaMask, Brave or Opera is added automatically by Web3modal
 */
export const WALLETCONNECT_PROVIDER_OPTIONS: IProviderOptions = {
  // Injected providers
  injected: {
    display: {
      name: 'MetaMask',
      description: 'Connect with the provider in your Browser',
    },
    package: null,
  },
  // WalletConnect provider
  walletconnect: {
    display: {
      name: 'WalletConnect',
      description: 'Connect with your mobile wallet',
    },
    package: WalletConnectProvider,
    options: {
      infuraId: INFURA_PROJECT_ID, // required
      qrcodeModalOptions: {
        mobileLinks: isMobile()
          ? ['rainbow', 'metamask', 'argent', 'trust']
          : [],
      },
    },
  },
};

/**
 * CORE CONTRACTS
 * @note as per https://github.com/openlawteam/tribute-contracts#architecture
 *
 * - DAO Registry (@note uses dao address for the contract address)
 * - DAO Factory
 */

// If developing locally, include your DaoRegistry contract address in your `.env` file.
export const DAO_REGISTRY_CONTRACT_ADDRESS =
  REACT_APP_DAO_REGISTRY_CONTRACT_ADDRESS;

export const DAO_FACTORY_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0xF44e53E7474588494B3BeC75898278050d99a8Ce',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xC97Aa4e619A9c7B6c6c2A823e361FC9B7f8f724B',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xc4807bA07c97E0E9c2d7eb5E065Ade50f7c7FA19',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const BANK_FACTORY_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0xF87Ba5851b45BDb2971DCf3a2125Dea6795E1436',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x31a6bE3294B20800018034bB81B4CDac7E4F4cFa',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x31741312df6B2eb4ACC13880485b0cAd97AeEc3a',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

// @todo
export const NFT_COLLECTION_FACTORY_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0x9c774856e2B4dF3ad9D27228B62A64d9e5b800Af',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x6c564d9Ec83c112995ff5e401D436343CA82769B',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x9ccc2D9785B5066Ea7b723B48d6876A335b62AAF',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

// @todo
export const ERC20_TOKEN_FACTORY_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0xb92C59031b5f5675F7AEffF43f909E93DF3d3e55',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x06457c20002FD7eD726784710Bc32F4F9cC118Ef',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x862d71d6E9Be8a7495EfEBa6f2b657b00E629c95',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

/**
 * ADAPTER CONTRACTS
 * @note as per https://github.com/openlawteam/tribute-contracts#architecture
 *
 * - Configuration
 * - CouponOnboardingContract
 * - Managing
 * - Onboarding
 * - Voting
 * - Offchain voting
 * - Financing
 * - Tribute
 * - Distribute
 * - Rage quit
 * - Guild kick
 * - BankAdapter
 * - TributeNFT
 * - NFTAdapter
 * - DaoRegistryAdapter
 * - KycOnboarding
 */

export const VOTING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0x3B06Fa591497c231A9fb9f5E7eA95B715728eaCf',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x9610166ffAD87B9d16e6C7CD4d5d944B8A40fEde',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x8980929914c82eF34767cA927505F2ff8479dA66',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const CONFIGURATION_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0xCD4b3abb35cCfF50994A756BF519A05f4E08c3D6',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xA4D2602C6c6F9E279660b8E3Ed458e3a2ac485ef',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x9c6457A7aE2BDD145Aa88ec8B13A622738e510aC',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const RAGEQUIT_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xDD6c858084f493FDd89f756822D9d12186Bda3Ca',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x6E2A69c1374cf927F3167c118eA37124C386b7C8',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const MANAGING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0xDC6B27aFA6a6Fa844102F2819c8C053c0BF9b4B8',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xFD0eB19665Ac2b2f18467C78B48f2571465116Bb',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xcf2C0C0c2B90E95d6F1484CB16db260a7f775074',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const FINANCING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x6431D54bd50E095c94a675E97535FfFe3ed9f1fA',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x01De2B9237f23aBf080011C8bFed5aa458683d15',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const ONBOARDING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xBFb4A24A0CEc69C05873767E0bCF2975B3393f55',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xAce6eCbb6aa178E1bA8e78cF3C221CEA45dA3d3d',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const GUILDKICK_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0x7c8243E3AE58E2A16Fdc6D1F5CD8F2E4a063f6B9',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xb0881B66fF8c79167F17A973267942e11521D3c5',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xB0464BE65De11ff3aF67130a5C3875880f29DB54',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const DAO_REGISTRY_ADAPTER_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0xe96e170F921Bd87C9B46F3f64cc64Af09119EccF',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x598177F911ab900f08979aA546bE84B3474cae2e',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x4d4243f2d605282CBd27C8391f05b77316d1a561',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const BANK_ADAPTER_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0xc089c6eB34A9383458a9b6465C57095D77De9997',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xAcD499348c5e0052Ce9273ca29249642829ba72D',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xD809727822307d87BD8AEb477C83D1EacAD75890',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const NFT_ADAPTER_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0xFDa6d913A5AA9Dbed71f0ca2b70E7630C82931DE',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xebA8D387b8cC13ff60aF394a9F3ED938737A6f56',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x398056137FCCf2807563AAeC50d14721e10A91f2',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const COUPONONBOARDING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0xC3718BAAA733dA19645c358930f06e3f8fD4e2b3',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xA1314b13D1A9EFF91A54Bd2267Ba8d1a9AC2fE73',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x8575af8CFCB7f6099D66ad9d89be93D426Be58Ce',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const TRIBUTE_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x2dE31fc5CB67Fed6B531a206f746893D8517BeE0',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x4d4243f2d605282CBd27C8391f05b77316d1a561',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const DISTRIBUTE_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0xC7FBe469d5A290635aB7A9Fd7E76c11700054170',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x6133F49D4f3f5dc1b5464f01994890d035cB859c',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const TRIBUTE_NFT_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0xA8a0D6f48f8919250ceA7BBdB10add96658046d7',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x602EA5DE7B7b06625127dF80D18b83589d408e80',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0x56A297D77e7703151C48a071F7f070DEc6603eE3',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const OFFCHAINVOTING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '0x8CE2EF998bdfcA14Ae6ea8CC9223c33A995aeF9e',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '0x4161850209aa4C5fc67EC7492930319530D7204c',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '0xF320E5245b164759BE6F1b834F056D5eEc3D8178',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

export const KYC_ONBOARDING_CONTRACT_ADDRESS = {
  [CHAINS.MAINNET]: '',
  [CHAINS.ROPSTEN]: '',
  [CHAINS.RINKEBY]: '',
  [CHAINS.GOERLI]: '',
  [CHAINS.KOVAN]: '',
  [CHAINS.GANACHE]: '',
  [CHAINS.HARMONY_TEST]: '',
  [CHAINS.HARMONY_MAIN]: '',
  [CHAINS.POLYGON_TEST]: '',
  [CHAINS.POLYGON]: '',
};

// If developing locally, include your Multicall contract address in your `.env` file.
export const MULTICALL_CONTRACT_ADDRESS = REACT_APP_MULTICALL_CONTRACT_ADDRESS;

/**
 * These addresses are important as the contracts use them in their configs.
 *
 * @todo Remove and get from the chain/subgraph?
 *
 * @see https://github.com/openlawteam/tribute-contracts/blob/9e0e03616a00e41e666351e146ee109b9fe37fb2/utils/DaoFactory.js
 */
export const GUILD_ADDRESS: string =
  '0x000000000000000000000000000000000000dead';
export const TOTAL_ADDRESS: string =
  '0x000000000000000000000000000000000000babe';
export const UNITS_ADDRESS: string =
  '0x00000000000000000000000000000000000FF1CE';
export const LOOT_ADDRESS: string =
  '0x00000000000000000000000000000000B105F00D';
export const MEMBER_COUNT_ADDRESS: string =
  '0x00000000000000000000000000000000DECAFBAD';
export const ETH_TOKEN_ADDRESS: string =
  '0x0000000000000000000000000000000000000000';
export const DAI_TOKEN_ADDRESS: string =
  '0x95b58a6bff3d14b7db2f5cb5f0ad413dc2940658';

/**
 * `SPACE` is used inside Snapshot Hub for matching a `space`
 * with its own proposals and votes.
 */
export const SPACE: string | undefined = REACT_APP_SNAPSHOT_SPACE;

/**
 * POLLING INTERVAL FOR GQL QUERIES
 * localhost | development - ms, poll every 5sec = 5000
 * production - ms, poll every 10sec = 10000
 */
export const GQL_QUERY_POLLING_INTERVAL: number =
  REACT_APP_ENVIRONMENT === 'production' ? 10000 : 5000;

// OpenSea API Key
export const OPENSEA_API_KEY: string | undefined = REACT_APP_OPENSEA_API_KEY;

export const DOCS_URL: string = 'https://docs.muse0.xyz';

// Admin accounts are used for internal operations. For example, if the optional
// contact email has been provided for an NFT Tribute proposal, then an admin
// account can view that email in the proposal details UI.
export const ADMIN_ACCOUNTS: string | undefined = REACT_APP_ADMIN_ACCOUNTS;

// If this optional address has been provided, the DAO NFT collection will
// display any NFTs owned by the address (in addition to those NFTs that have
// been collected in the DAO's NFT extension contract).
export const DAO_NFT_ADDITIONAL_OWNER: string | undefined =
  REACT_APP_DAO_NFT_ADDITIONAL_OWNER;
