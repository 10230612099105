/**
 * isHTMLVideoFormat
 *
 * Checks if src URL is a supported HTML video format
 *
 * @param {string} url
 * @returns {boolean}
 */
export function isHTMLVideoFormat(url: string): boolean {
  const regex = /\.(?:mp4|ogg|webm)$/i;
  return regex.test(url);
}
