import {useState, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AbiItem} from 'web3-utils';
import {useLazyQuery} from '@apollo/react-hooks';

import {NFTTokenData} from '../types';
import {AsyncStatus} from '../../../util/types';
import {StoreState} from '../../../store/types';
import {SubgraphNetworkStatus} from '../../../store/subgraphNetworkStatus/types';
import {useWeb3Modal} from '../../../components/web3/hooks';
import {multicall, MulticallTuple} from '../../../components/web3/helpers';
import {GET_NFT_COLLECTION} from '../../../gql';

type UseNFTCollectionReturn = {
  collectedNFTs: NFTTokenData[];
  collectedNFTsStatus: AsyncStatus;
  collectedNFTsError: Error | undefined;
};

/**
 * useNFTCollection
 *
 * Gets list of NFTs in DAO collection (only the NFTs that have been collected into the NFT extension contract).
 *
 * @returns {UseNFTCollectionReturn}
 */
export function useNFTCollection(): UseNFTCollectionReturn {
  /**
   * Selectors
   */

  const DaoRegistryContract = useSelector(
    (state: StoreState) => state.contracts.DaoRegistryContract
  );
  const NFTExtensionContract = useSelector(
    (state: StoreState) => state.contracts.NFTExtensionContract
  );
  const subgraphNetworkStatus = useSelector(
    (state: StoreState) => state.subgraphNetworkStatus.status
  );

  /**
   * Our hooks
   */

  const {web3Instance} = useWeb3Modal();

  /**
   * GQL Query
   */

  const [getNFTsFromSubgraphResult, {called, data, error, loading}] =
    useLazyQuery(GET_NFT_COLLECTION, {
      variables: {
        daoAddress: DaoRegistryContract?.contractAddress.toLowerCase(),
      },
      fetchPolicy: 'cache-and-network',
    });

  /**
   * State
   */

  const [collectedNFTs, setCollectedNFTs] = useState<NFTTokenData[]>([]);
  const [collectedNFTsStatus, setCollectedNFTsStatus] = useState<AsyncStatus>(
    AsyncStatus.STANDBY
  );
  const [collectedNFTsError, setCollectedNFTsError] = useState<Error>();

  /**
   * Cached callbacks
   */

  const getNFTsFromExtensionCached = useCallback(getNFTsFromExtension, [
    NFTExtensionContract,
    web3Instance,
  ]);
  const getNFTsFromSubgraphCached = useCallback(getNFTsFromSubgraph, [
    data,
    error,
    getNFTsFromExtensionCached,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    if (!called) {
      getNFTsFromSubgraphResult();
    }
  }, [called, getNFTsFromSubgraphResult]);

  useEffect(() => {
    if (subgraphNetworkStatus === SubgraphNetworkStatus.OK) {
      if (called && !loading && DaoRegistryContract?.contractAddress) {
        getNFTsFromSubgraphCached();
      }
    } else {
      // If there is a subgraph network error fallback to fetching NFTs directly
      // from smart contracts
      getNFTsFromExtensionCached();
    }
  }, [
    DaoRegistryContract?.contractAddress,
    called,
    getNFTsFromExtensionCached,
    getNFTsFromSubgraphCached,
    loading,
    subgraphNetworkStatus,
  ]);

  /**
   * Functions
   */

  function getNFTsFromSubgraph() {
    try {
      setCollectedNFTsStatus(AsyncStatus.PENDING);

      if (data) {
        // extract NFTs from gql data
        const {
          nftCollection: {nfts},
        } = data.tributeDaos[0] as Record<string, any>;

        if (!nfts) {
          throw new Error('nfts are undefined');
        } else {
          setCollectedNFTs(nfts);
          setCollectedNFTsStatus(AsyncStatus.FULFILLED);
        }
      } else {
        if (error) {
          throw new Error(`subgraph query error: ${error.message}`);
        } else if (typeof data === 'undefined') {
          // Additional case to catch `{"errors":{"message":"No indexers found
          // for subgraph deployment"}}` which does not get returned as an error
          // by the graph query call.
          throw new Error('subgraph query error: data is undefined');
        }
      }
    } catch (error) {
      const {message} = error as Error;

      // If there is a subgraph query error fallback to fetching NFTs directly
      // from smart contracts
      console.log(message);

      getNFTsFromExtensionCached();
    }
  }

  async function getNFTsFromExtension() {
    if (!NFTExtensionContract || !web3Instance) {
      return;
    }

    try {
      setCollectedNFTsStatus(AsyncStatus.PENDING);

      let collectedItems: NFTTokenData[] = [];

      const {
        abi: nftExtensionABI,
        contractAddress: nftExtensionAddress,
        instance: {methods: nftExtensionMethods},
      } = NFTExtensionContract;

      const nbNFTAddresses = await nftExtensionMethods.nbNFTAddresses().call();

      if (Number(nbNFTAddresses) > 0) {
        // Build calls to get list of NFT addresses in GUILD collection
        const getNFTAddressABI = nftExtensionABI.find(
          (item) => item.name === 'getNFTAddress'
        );
        const getNFTAddressCalls = [
          ...Array(Number(nbNFTAddresses)).keys(),
        ].map(
          (index): MulticallTuple => [
            nftExtensionAddress,
            getNFTAddressABI as AbiItem,
            [index.toString()],
          ]
        );
        const nftAddresses: string[] = await multicall({
          calls: getNFTAddressCalls,
          web3Instance,
        });

        await Promise.all(
          nftAddresses.map(async (address: string) => {
            const nbNFTs = await nftExtensionMethods.nbNFTs(address).call();

            if (Number(nbNFTs) > 0) {
              // Build calls to get list of token IDs by each NFT address in GUILD
              // collection
              const getNFTABI = nftExtensionABI.find(
                (item) => item.name === 'getNFT'
              );
              const getNFTCalls = [...Array(Number(nbNFTs)).keys()].map(
                (index): MulticallTuple => [
                  nftExtensionAddress,
                  getNFTABI as AbiItem,
                  [address, index.toString()],
                ]
              );
              const ids: string[] = await multicall({
                calls: getNFTCalls,
                web3Instance,
              });
              ids.forEach((id: string) => {
                collectedItems.push({address, tokenId: id});
              });
            }
          })
        );
      }

      setCollectedNFTs(collectedItems);
      setCollectedNFTsStatus(AsyncStatus.FULFILLED);
    } catch (error) {
      setCollectedNFTs([]);
      setCollectedNFTsError(error);
      setCollectedNFTsStatus(AsyncStatus.REJECTED);
    }
  }

  return {collectedNFTs, collectedNFTsError, collectedNFTsStatus};
}
