import {OPENSEA_API_URL, OPENSEA_API_KEY} from '../../../config';

/**
 * getNFTAssetsByOwner
 *
 * Fetches NFTs by owner using the OpenSea API.
 *
 * @param {string} owner
 * @returns {Promise<Record<string, string>[]>}
 */
export async function getNFTAssetsByOwner(
  owner: string
): Promise<Record<string, string>[]> {
  let offset = 0;
  let fetchedGroupSize = 0;
  let fetchedNFTs: any = [];
  let options = {method: 'GET'};
  if (OPENSEA_API_KEY) {
    options['headers'] = {'X-API-KEY': OPENSEA_API_KEY};
  }

  do {
    try {
      const response = await fetch(
        `${OPENSEA_API_URL}/assets?owner=${owner}&limit=50&offset=${offset}`,
        options
      );
      const {assets} = await response.json();
      fetchedNFTs = [...fetchedNFTs, ...assets];
      fetchedGroupSize = assets.length;
      if (fetchedGroupSize === 50) {
        offset = offset + 50;
        // Wait between requests to mitigate API rate limiting.
        new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 1000);
        });
      }
    } catch (error) {
      throw new Error('Failed to fetch NFT JSON data from API');
    }
    // API request limit is 50 assets. Fetch next batch with adjusted offset
    // until request no longer returns max.
  } while (fetchedGroupSize === 50);

  return fetchedNFTs;
}
