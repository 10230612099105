import {SVGAttributes} from 'react';

export default function LeftArrowCircleSVG(
  props: SVGAttributes<HTMLOrSVGElement>
) {
  return (
    <svg
      width="60px"
      height="60px"
      viewBox="0 0 76 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g opacity="0.7">
        <circle
          cx="38.4922"
          cy="37.5"
          r="37"
          transform="rotate(-180 38.4922 37.5)"
          fill="white"
          stroke="currentColor"
        />
        <path
          d="M44.2805 25.8452L44.5072 51.825L21.8947 39.0314L44.2805 25.8452Z"
          fill="currentColor"
          stroke="currentColor"
        />
      </g>
    </svg>
  );
}
