import {Link, useHistory} from 'react-router-dom';
import {useState, useEffect} from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import LazyLoad from 'react-lazyload';

import {FetchedNFT} from './types';
import {
  is3DModelFormat,
  isHTMLVideoFormat,
  isIframeSafeFormat,
} from './helpers';
import Loader from '../feedback/Loader';
import {AsyncStatus} from '../../util/types';

type NFTTributeProposalCardProps = {
  /**
   * The path to link to. Defaults to `${location.pathname}/${proposalOnClickId}`.
   */
  linkPath?: string | ((id: string) => string);
  /**
   * Take arbitrary actions on click
   */
  onClick?: (proposalOnClickId: string) => void;
  /**
   * The ID for the proposal to be used for navigation.
   * As there can be a few different options, it's best to provide it
   * explicitly.
   */
  proposalOnClickId: string;
  /**
   * Render a custom status via render prop
   */
  renderStatus?: () => React.ReactNode;
  /**
   * NFT data to render in card
   */
  nftData?: FetchedNFT;
  /**
   * Status of fetching the NFT data for all NFTs in this NFT's proposal type
   * (e.g., failed, nonsponsored, passed, voting)
   */
  nftProposalTypeStatus: AsyncStatus;
};

const PLACEHOLDER = '\u2014'; /* em dash */

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

/**
 * Shows a preview of a proposal's details
 *
 * @param {NFTTributeProposalCardProps} props
 * @returns {JSX.Element}
 */
export default function NFTTributeProposalCard(
  props: NFTTributeProposalCardProps
): JSX.Element {
  const {
    linkPath,
    nftData,
    nftProposalTypeStatus,
    onClick,
    proposalOnClickId,
    renderStatus,
  } = props;

  /**
   * Their hooks
   */

  const {location} = useHistory();

  /**
   * Variables
   */

  const pathname: string = location.pathname === '/' ? '' : location.pathname;

  const path: string =
    typeof linkPath === 'function'
      ? linkPath(proposalOnClickId)
      : linkPath || `${pathname}/${proposalOnClickId}`;

  /**
   * State
   */

  const [imageDataURL, setImageDataURL] = useState<string>();

  /**
   * Effects
   */

  useEffect(() => {
    if (nftData?.image_data) {
      const svg = nftData.image_data;
      const blob = new Blob([svg], {type: 'image/svg+xml'});
      setImageDataURL(URL.createObjectURL(blob));
    }

    return function cleanup() {
      // Make sure to revoke the data URIs to avoid memory leaks
      imageDataURL && URL.revokeObjectURL(imageDataURL);
    };
  }, [imageDataURL, nftData?.image_data]);

  /**
   * Functions
   */

  function handleClick() {
    onClick?.(proposalOnClickId);
  }

  function renderImage(): React.ReactNode {
    // Render loading
    if (
      nftProposalTypeStatus === AsyncStatus.STANDBY ||
      nftProposalTypeStatus === AsyncStatus.PENDING
    ) {
      return (
        <div className="thumb">
          <Loader />
        </div>
      );
    }

    if (!nftData) return;

    // detect video file first to display
    if (nftData.animation_url) {
      return (
        <div className="thumb">
          <LazyLoad once placeholder={<Loader />} height={20} offset={250}>
            {isHTMLVideoFormat(nftData.animation_url) ? (
              <video
                className="preview-file"
                controlsList="nodownload"
                loop
                playsInline
                src={nftData.animation_url}
              />
            ) : // Use image in card if available instead of animation
            nftData.image_url ? (
              <img
                className="preview-file"
                src={nftData.image_url}
                alt="preview"
              />
            ) : is3DModelFormat(nftData.animation_url) ? (
              <model-viewer
                src={nftData.animation_url}
                auto-rotate
                autoplay
                camera-controls
                ar-status="not-presenting"></model-viewer>
            ) : isIframeSafeFormat(nftData.animation_url) ? (
              <iframe
                className="preview-file"
                src={nftData.animation_url}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                title="preview"
              />
            ) : (
              // fall back to placeholder text if src is not trusted format
              <div className="no-image-available">Media type not supported</div>
            )}
          </LazyLoad>
        </div>
      );
    }

    // then raw image data (e.g., svg)
    if (nftData.image_data) {
      return (
        <div className="thumb">
          <LazyLoad once placeholder={<Loader />} height={20} offset={250}>
            <img className="preview-file" src={imageDataURL} alt="preview" />
          </LazyLoad>
        </div>
      );
    }

    // then image file
    if (nftData.image_url) {
      return (
        <div className="thumb">
          <LazyLoad once placeholder={<Loader />} height={20} offset={250}>
            {/* Handle metadata where a video file is set for `image_url` (instead of the expected `animation_url`) */}
            {isHTMLVideoFormat(nftData.image_url) ? (
              <video
                className="preview-file"
                controlsList="nodownload"
                loop
                playsInline
                src={nftData.image_url}
              />
            ) : (
              <img
                className="preview-file"
                src={nftData.image_url}
                alt="preview"
              />
            )}
          </LazyLoad>
        </div>
      );
    }

    return <div className="no-image-available">No image available</div>;
  }

  /**
   * Render
   */

  return (
    <Link className="nft-proposalcard__link" to={path} onClick={handleClick}>
      <div className="nft-proposalcard">
        {/* IMAGE */}
        <div className="nft-proposalcard__image-container">{renderImage()}</div>

        {/* TITLE */}
        <h3 className="nft-proposalcard__title">
          <ResponsiveEllipsis
            text={nftData?.tokenURIName || PLACEHOLDER}
            maxLine={1}
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </h3>

        {/* SUBTITLE */}
        <div className="nft-proposalcard__subtitle">
          <ResponsiveEllipsis
            text={nftData?.tokenName || PLACEHOLDER}
            maxLine={1}
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </div>

        {/* VOTING PROGRESS STATUS AND BAR */}
        {renderStatus && renderStatus()}
      </div>
    </Link>
  );
}
