import {AbiItem} from 'web3-utils';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {CheckboxSize} from '../common/Checkbox';
import {CycleEllipsis} from '../feedback';
import {ERC721} from '../../../abi-types/ERC721';
import {FetchedNFT} from './types';
import {getConnectedMember} from '../../store/actions';
import {ProposalData} from '../proposals/types';
import {ReduxDispatch, StoreState} from '../../store/types';
import {truncateEthAddress} from '../../util/helpers';
import {TX_CYCLE_MESSAGES} from '../web3/config';
import {useContractSend, useETHGasPrice, useWeb3Modal} from '../web3/hooks';
import {useDaoTokenDetails} from '../dao-token/hooks';
import {useMemberActionDisabled} from '../../hooks';
import {Web3TxStatus} from '../web3/types';
import CycleMessage from '../feedback/CycleMessage';
import ErrorMessageWithDetails from '../common/ErrorMessageWithDetails';
import EtherscanURL from '../web3/EtherscanURL';
import FadeIn from '../common/FadeIn';
import Loader from '../feedback/Loader';
import Muse0Text from '../common/Muse0Text';
import NFTMemberTermsModal from '../nfts/NFTMemberTermsModal';

type ProcessArguments = [
  string, // `dao`
  string // `proposalId`
];

type TokenApproveArguments = [
  string, // `spender`
  string // `value`
];

type NFTTributeProposalDetails = {
  tokenAddress: string;
  tokenId: string;
};

type ProcessActionNFTTributeProps = {
  disabled?: boolean;
  proposal: ProposalData;
  nft?: FetchedNFT;
};

type ActionDisabledReasons = {
  notNFTOwnerMessage: string;
};

/**
 * Cached outside the component to prevent infinite re-renders.
 *
 * The same can be accomplished inside the component using
 * `useState`, `useRef`, etc., depending on the use case.
 */
const useMemberActionDisabledProps = {
  // Anyone can process a proposal - it's just a question of gas payment.
  skipIsActiveMemberCheck: true,
};

const DAO_EMAIL = 'members@muse0.xyz';

export default function ProcessActionNFTTribute(
  props: ProcessActionNFTTributeProps
) {
  const {
    disabled: propsDisabled,
    proposal: {snapshotProposal},
    nft,
  } = props;

  /**
   * State
   */

  const [submitError, setSubmitError] = useState<Error>();

  const [nftTributeProposalDetails, setNFTTributeProposalDetails] =
    useState<NFTTributeProposalDetails>();

  const [showTermsCheckbox, setShowTermsCheckbox] = useState<boolean>(false);

  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);

  const [termsCheckboxChecked, setTermsCheckboxChecked] =
    useState<boolean>(false);

  /**
   * Refs
   */

  const actionDisabledReasonsRef = useRef<ActionDisabledReasons>({
    notNFTOwnerMessage: '',
  });

  /**
   * Selectors
   */

  const TributeNFTContract = useSelector(
    (s: StoreState) => s.contracts?.TributeNFTContract
  );

  const nftExtensionAddress = useSelector(
    (s: StoreState) => s.contracts?.NFTExtensionContract?.contractAddress
  );

  const daoRegistryContract = useSelector(
    (s: StoreState) => s.contracts.DaoRegistryContract
  );

  /**
   * Our hooks
   */

  const {account, web3Instance} = useWeb3Modal();

  const {average: gasPrice} = useETHGasPrice();

  const {txEtherscanURL, txIsPromptOpen, txSend, txStatus} = useContractSend();

  const {
    txEtherscanURL: txEtherscanURLTokenApprove,
    txIsPromptOpen: txIsPromptOpenTokenApprove,
    txSend: txSendTokenApprove,
    txStatus: txStatusTokenApprove,
  } = useContractSend();

  const {
    isDisabled,
    openWhyDisabledModal,
    WhyDisabledModal,
    setOtherDisabledReasons,
  } = useMemberActionDisabled(useMemberActionDisabledProps);

  const {daoTokenDetails} = useDaoTokenDetails();

  /**
   * Their hooks
   */

  const dispatch = useDispatch<ReduxDispatch>();

  /**
   * Variables
   */

  const isInProcess =
    txStatus === Web3TxStatus.AWAITING_CONFIRM ||
    txStatus === Web3TxStatus.PENDING ||
    txStatusTokenApprove === Web3TxStatus.AWAITING_CONFIRM ||
    txStatusTokenApprove === Web3TxStatus.PENDING;

  const isDone = txStatus === Web3TxStatus.FULFILLED;

  const isInProcessOrDone =
    isInProcess || isDone || txIsPromptOpen || txIsPromptOpenTokenApprove;

  const areSomeDisabled = isDisabled || isInProcessOrDone || propsDisabled;

  const isTermsCheckboxUnchecked = showTermsCheckbox && !termsCheckboxChecked;

  const isNonERC721Schema = nft && nft.schema !== 'ERC721';

  /**
   * Cached callbacks
   */

  const getNFTTributeProposalDetailsCached = useCallback(
    getNFTTributeProposalDetails,
    [TributeNFTContract, daoRegistryContract?.contractAddress, snapshotProposal]
  );

  /**
   * Effects
   */

  useEffect(() => {
    getNFTTributeProposalDetailsCached();
  }, [getNFTTributeProposalDetailsCached]);

  useEffect(() => {
    if (!isNonERC721Schema) {
      // 1. Determine and set reasons why action would be disabled

      // Reason: For this proposal type, a passed proposal can only be processed
      // by the owner of the asset to be transferred
      if (nft && account) {
        actionDisabledReasonsRef.current = {
          ...actionDisabledReasonsRef.current,
          notNFTOwnerMessage:
            nft.owner.toLowerCase() !== account.toLowerCase()
              ? `Only the current NFT owner (${truncateEthAddress(
                  nft.owner,
                  7
                )}) can process the transfer.`
              : '',
        };
      }

      // 2. Set reasons
      setOtherDisabledReasons(Object.values(actionDisabledReasonsRef.current));
    }
  }, [account, isNonERC721Schema, nft, setOtherDisabledReasons]);

  // Show checkbox to accept terms if NFT can be transferred (is ERC721 schema
  // type) and connected user is NFT owner
  useEffect(() => {
    if (!isNonERC721Schema) {
      if (nft && account) {
        if (nft.owner.toLowerCase() === account.toLowerCase()) {
          setShowTermsCheckbox(true);
        } else {
          setShowTermsCheckbox(false);
          setTermsCheckboxChecked(false);
        }
      } else {
        setShowTermsCheckbox(false);
        setTermsCheckboxChecked(false);
      }
    }
  }, [account, isNonERC721Schema, nft]);

  /**
   * Functions
   */

  async function getNFTTributeProposalDetails() {
    try {
      if (
        !daoRegistryContract?.contractAddress ||
        !snapshotProposal ||
        !TributeNFTContract
      )
        return;

      const proposalDetails = await TributeNFTContract.instance.methods
        .proposals(
          daoRegistryContract.contractAddress,
          snapshotProposal.idInDAO
        )
        .call();
      const {nftAddr: tokenAddress, nftTokenId: tokenId} = proposalDetails;

      setNFTTributeProposalDetails({tokenAddress, tokenId});
    } catch (error) {
      console.error(error);
      setNFTTributeProposalDetails(undefined);
    }
  }

  async function handleSubmitTokenApprove() {
    try {
      if (!nftTributeProposalDetails) {
        throw new Error('No Tribute proposal details found.');
      }

      if (!TributeNFTContract) {
        throw new Error('No TributeNFTContract found.');
      }

      if (!nftExtensionAddress) {
        throw new Error('No NFT Extension address found.');
      }

      if (!account) {
        throw new Error('No account found.');
      }

      if (!web3Instance) {
        throw new Error('No Web3 instance was found.');
      }

      const {tokenAddress, tokenId} = nftTributeProposalDetails;

      const {default: lazyERC721ABI} = await import('../../abis/ERC721.json');
      const erc721Contract: AbiItem[] = lazyERC721ABI as any;
      const erc721Instance = new web3Instance.eth.Contract(
        erc721Contract,
        tokenAddress
      ) as any as ERC721; // TypeChain: for Web3 we need to cast;

      // Check if NFT extension is allowed to transfer NFT on behalf of owner.
      // If not, the owner will approve the NFT extension.
      const approvedAddress = await erc721Instance.methods
        .getApproved(tokenId)
        .call();

      if (approvedAddress.toLowerCase() !== nftExtensionAddress.toLowerCase()) {
        try {
          const tokenApproveArguments: TokenApproveArguments = [
            nftExtensionAddress,
            tokenId,
          ];
          const txArguments = {
            from: account || '',
            ...(gasPrice ? {gasPrice} : null),
          };

          // Execute contract call for `approve`
          await txSendTokenApprove(
            'approve',
            erc721Instance.methods,
            tokenApproveArguments,
            txArguments
          );
        } catch (error) {
          throw error;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async function handleSubmit() {
    try {
      if (!daoRegistryContract) {
        throw new Error('No DAO Registry contract was found.');
      }

      if (!snapshotProposal) {
        throw new Error('No Snapshot proposal found.');
      }

      if (!TributeNFTContract) {
        throw new Error('No TributeNFTContract found.');
      }

      if (!account) {
        throw new Error('No account found.');
      }

      if (!web3Instance) {
        throw new Error('No Web3 instance was found.');
      }

      await handleSubmitTokenApprove();

      const processArguments: ProcessArguments = [
        daoRegistryContract.contractAddress,
        snapshotProposal.idInDAO,
      ];

      const txArguments = {
        from: account || '',
        ...(gasPrice ? {gasPrice} : null),
      };

      const tx = await txSend(
        'processProposal',
        TributeNFTContract.instance.methods,
        processArguments,
        txArguments
      );

      if (tx) {
        // re-fetch member
        await dispatch(
          getConnectedMember({account, daoRegistryContract, web3Instance})
        );

        // if connected account is the applicant (the address that will receive
        // the membership units) suggest adding DAO token to wallet
        if (
          account.toLowerCase() ===
          snapshotProposal.msg.payload.metadata.submitActionArgs[0].toLowerCase()
        ) {
          await addTokenToWallet();
        }
      }
    } catch (error) {
      setSubmitError(error);
    }
  }

  function handleCheckboxClicked(event: React.ChangeEvent<HTMLInputElement>) {
    const {checked} = event.target;

    setTermsCheckboxChecked(checked);
  }

  async function addTokenToWallet() {
    if (!daoTokenDetails) return;

    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: daoTokenDetails,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Render
   */

  function renderSubmitStatus(): React.ReactNode {
    // token approve transaction statuses
    if (txStatusTokenApprove === Web3TxStatus.AWAITING_CONFIRM) {
      return (
        <>
          Confirm to transfer your NFT
          <CycleEllipsis />
        </>
      );
    }

    if (txStatusTokenApprove === Web3TxStatus.PENDING) {
      return (
        <>
          <div>
            Approving your NFT for transfer
            <CycleEllipsis />
          </div>
          <EtherscanURL url={txEtherscanURLTokenApprove} isPending />
        </>
      );
    }

    // process proposal transaction statuses
    switch (txStatus) {
      case Web3TxStatus.AWAITING_CONFIRM:
        return (
          <>
            Confirm to finalize membership
            <CycleEllipsis />
          </>
        );
      case Web3TxStatus.PENDING:
        return (
          <>
            <CycleMessage
              intervalMs={2000}
              messages={TX_CYCLE_MESSAGES}
              useFirstItemStart
              render={(message) => {
                return <FadeIn key={message}>{message}</FadeIn>;
              }}
            />

            <EtherscanURL url={txEtherscanURL} isPending />
          </>
        );
      case Web3TxStatus.FULFILLED:
        return (
          <>
            <div>Transfer completed!</div>

            <EtherscanURL url={txEtherscanURL} />
          </>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div>
        {/* TRANSFER BUTTON */}
        <button
          className="proposaldetails__button"
          disabled={
            areSomeDisabled || isTermsCheckboxUnchecked || isNonERC721Schema
          }
          onClick={
            areSomeDisabled || isTermsCheckboxUnchecked || isNonERC721Schema
              ? () => {}
              : handleSubmit
          }>
          {isInProcess ? <Loader /> : isDone ? 'Done' : 'Transfer NFT'}
        </button>

        {/* Proposal with non-ERC721 type NFT will not be processed. */}
        {isNonERC721Schema && (
          <div className="nft-proposaldetails__text">
            Automated transfer of this NFT type is currently not supported. The
            proposer should contact{' '}
            <a
              href={`mailto:${DAO_EMAIL}`}
              target="_blank"
              rel="noopener noreferrer">
              {DAO_EMAIL}
            </a>{' '}
            for instructions on how to transfer the NFT and claim membership
            tokens.
          </div>
        )}

        {/* ACCEPT TERMS */}
        {showTermsCheckbox && (
          <div className="nft-proposaldetails__terms-checkbox">
            <input
              aria-checked={termsCheckboxChecked}
              className="checkbox-input"
              id="termsAccept"
              name="termsAccept"
              onChange={areSomeDisabled ? () => {} : handleCheckboxClicked}
              type="checkbox"
              disabled={areSomeDisabled}
            />

            <label className="checkbox-label" htmlFor="termsAccept">
              <span className={`checkbox-box ${CheckboxSize.SMALL}`}></span>
              <span className="checkbox-text">
                I have read and agree to the{' '}
                <button
                  className="checkbox-text__button"
                  onClick={() => setShowTermsModal(true)}>
                  terms
                </button>{' '}
                of transferring my NFT to <Muse0Text /> and becoming a member of
                the DAO.
              </span>
            </label>
          </div>
        )}

        <ErrorMessageWithDetails
          error={submitError}
          renderText="Something went wrong"
        />

        {/* SUBMIT STATUS */}
        {isInProcessOrDone && (
          <div className="form__submit-status-container">
            {renderSubmitStatus()}
          </div>
        )}

        {/* DISABLED REASON BUTTON */}
        {isDisabled && !isNonERC721Schema && (
          <button
            className="button--help-centered"
            onClick={openWhyDisabledModal}>
            Why is transfer disabled?
          </button>
        )}
      </div>

      <WhyDisabledModal title="Why is transfer disabled?" />

      {/* TERMS MODAL */}
      {showTermsModal && (
        <NFTMemberTermsModal
          isOpen={showTermsModal}
          closeHandler={() => {
            setShowTermsModal(false);
          }}
        />
      )}
    </>
  );
}
