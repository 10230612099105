import {gql} from '@apollo/client';

export const GET_COLLECTED_NFT = gql`
  query GetCollectedNFT(
    $daoAddress: String
    $nftAddress: String
    $tokenId: String
  ) {
    tributeDaos(where: {daoAddress: $daoAddress}) {
      nftCollection {
        nfts(where: {nftAddress: $nftAddress, tokenId: $tokenId}) {
          address: nftAddress
          tokenId
        }
      }
    }
  }
`;
