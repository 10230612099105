/**
 * is3DmodelFormat
 *
 * Checks if src URL is a supported 3D model format
 *
 * @param {string} url
 * @returns {boolean}
 */
export function is3DModelFormat(url: string): boolean {
  const regex = /\.(?:gltf|glb)$/i;
  return regex.test(url);
}
