import {memo, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';

import {CenterLogo} from '../../components/logo';
import {DOCS_URL} from '../../config';
import {NavHamburger} from '../../components/Nav';
import {StoreState} from '../../store/types';
import {useSelector} from 'react-redux';
import {useWeb3Modal} from '../../components/web3/hooks';
import alien from '../../assets/images/alien.png';
import FadeIn from '../../components/common/FadeIn';
import SocialMedia from '../../components/common/SocialMedia';
import Wrap from '../../components/common/Wrap';

const Alien = memo(() => {
  return (
    <div
      className="landing__image"
      data-testid="alien"
      data-aos="fade-up"
      data-aos-delay="150">
      <img alt="Alien CryptoPunk" src={alien} />
    </div>
  );
});

function GetStartedHeader() {
  /**
   * Selectors
   */

  const isActiveMember = useSelector(
    (s: StoreState) => s.connectedMember?.isActiveMember
  );

  /**
   * Our hooks
   */

  const {account} = useWeb3Modal();

  function NavLinks() {
    return (
      <nav role="navigation" id="navigation">
        <ul className="nav__list" data-testid="nav__list">
          <li tabIndex={0}>
            <NavLink to="/collection">
              <span>Collection</span>
            </NavLink>
          </li>
          <li tabIndex={0}>
            <NavLink to="/curation">
              <span>Curate</span>
            </NavLink>
          </li>
          {account && isActiveMember ? (
            <li tabIndex={0}>
              <NavLink to="/governance">
                <span>Govern</span>
              </NavLink>
            </li>
          ) : (
            <li tabIndex={0}>
              <NavLink to="/donate">
                <span>Donate</span>
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    );
  }

  return (
    <div data-testid="get-started-header" className="nav-header">
      <div className="nav-header__menu-container">
        {/* NAV */}
        <NavLinks />
        <NavHamburger />
      </div>
    </div>
  );
}

function GetStartedFooter() {
  return (
    <div className="footer">
      <a href={DOCS_URL} target="_blank" rel="noopener noreferrer">
        Docs
      </a>
      <SocialMedia />
    </div>
  );
}

export default function GetStarted() {
  /**
   * Effects
   */

  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 40,
      delay: 120,
      mirror: false,
      once: true,
    });
  }, []);

  /**
   * Render
   */

  return (
    <div className="landing-wrapper">
      <GetStartedHeader />
      <Wrap className="section-wrapper">
        <FadeIn>
          <CenterLogo />

          <div className="landing">
            <div className="landing__image-frame">
              <div className="landing__subtitle typewriter">
                an Internet museum created by an alien
              </div>

              <Alien />

              <div className="landing__subtitle--rotated typewriter--rotated">
                curated by artists and collectors
              </div>
            </div>

            <a
              className="landing__button"
              data-text="MISSION"
              href={DOCS_URL}
              target="_blank"
              rel="noopener noreferrer"
              role="button">
              Mission
            </a>
          </div>
        </FadeIn>
      </Wrap>
      <GetStartedFooter />
    </div>
  );
}
