import {OPENSEA_API_URL, OPENSEA_API_KEY} from '../../../config';

/**
 * getNFTAsset
 *
 * Fetches NFT by contractAddress and tokenId using the OpenSea API.
 *
 * @param {string} contractAddress
 * @param {string} tokenId
 * @returns {Promise<Record<string, string>>}
 */
export async function getNFTAsset(
  contractAddress: string,
  tokenId: string
): Promise<Record<string, string>> {
  try {
    let options = {method: 'GET'};
    if (OPENSEA_API_KEY) {
      options['headers'] = {'X-API-KEY': OPENSEA_API_KEY};
    }
    const response = await fetch(
      `${OPENSEA_API_URL}/asset/${contractAddress}/${tokenId}`,
      options
    );

    if (response.status === 404) {
      throw new Error('NFT not found.');
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}
