import {useEffect, useState} from 'react';
import Markdown from 'markdown-to-jsx';

import {ADMIN_ACCOUNTS, OPENSEA_ASSETS_URL} from '../../config';
import {useWeb3Modal} from '../web3/hooks/useWeb3Modal';
import {ProposalData} from '../proposals/types';
import {AsyncStatus} from '../../util/types';
import {normalizeString} from '../../util/helpers';
import {
  is3DModelFormat,
  isHTMLVideoFormat,
  isIframeSafeFormat,
} from './helpers';
import Loader from '../feedback/Loader';
import ExternalLinkSVG from '../../assets/svg/ExternalLinkSVG';
import {UseNFTAssetReturn} from './hooks';

type NFTTributeProposalDetailsProps = {
  proposal: ProposalData;
  /**
   * A render prop to render any action button flows for a proposal.
   */
  renderActions: () => React.ReactNode;
  nftAssetInfo: UseNFTAssetReturn;
};

const PLACEHOLDER = '\u2014'; /* em dash */

export default function NFTTributeProposalDetails(
  props: NFTTributeProposalDetailsProps
) {
  const {
    proposal,
    renderActions,
    nftAssetInfo: {nft, nftError, nftStatus},
  } = props;

  /**
   * Our hooks
   */

  const {account} = useWeb3Modal();

  /**
   * Variables
   */

  const isLoading: boolean = nftStatus === AsyncStatus.PENDING;
  const error: Error | undefined = nftError;
  const commonData = proposal.getCommonSnapshotProposalData();

  /**
   * State
   */

  const [imageDataURL, setImageDataURL] = useState<string>();

  /**
   * Effects
   */

  useEffect(() => {
    if (nft?.image_data) {
      const svg = nft.image_data;
      const blob = new Blob([svg], {type: 'image/svg+xml'});
      setImageDataURL(URL.createObjectURL(blob));
    }

    return function cleanup() {
      // Make sure to revoke the data URIs to avoid memory leaks
      imageDataURL && URL.revokeObjectURL(imageDataURL);
    };
  }, [imageDataURL, nft?.image_data]);

  /**
   * Functions
   */

  function renderNFT(): React.ReactNode {
    // Render loading
    if (isLoading && !error) {
      return (
        <div style={{display: 'inline-block'}}>
          <Loader text="Loading NFT..." />
        </div>
      );
    }

    // Render error
    if (error) {
      return (
        <div className="error-message">Unable to load NFT: {error.message}</div>
      );
    }

    // Render NFT
    return (
      <>
        {/* PROPOSAL NAME (TOKEN URI NAME) */}
        <h3 className="nft-proposaldetails__title">
          {nft?.tokenURIName || PLACEHOLDER}
          {renderNFTDetailsLink()}
        </h3>

        {/* NFT DETAILS */}
        <div className="nft-proposaldetails__subtitle">
          {nft?.tokenName || PLACEHOLDER}
        </div>
        <div className="nft-proposaldetails__nft-wrapper">
          <div className="nft-proposaldetails__image-container">
            {renderImage()}
          </div>
        </div>
      </>
    );
  }

  function renderImage(): React.ReactNode {
    if (!nft) return;

    // detect video file first to display
    if (nft.animation_url) {
      return (
        <div className="thumb">
          {isHTMLVideoFormat(nft.animation_url) ? (
            <video
              className="preview-file"
              controls
              autoPlay
              controlsList="nodownload"
              loop
              playsInline
              src={nft.animation_url}
            />
          ) : is3DModelFormat(nft.animation_url) ? (
            <model-viewer
              src={nft.animation_url}
              auto-rotate
              autoplay
              camera-controls
              ar-status="not-presenting"></model-viewer>
          ) : isIframeSafeFormat(nft.animation_url) ? (
            <iframe
              className="preview-file"
              src={nft.animation_url}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title="preview"
            />
          ) : (
            // fall back to placeholder text if src is not trusted format
            <div className="no-image-available">Media type not supported</div>
          )}
        </div>
      );
    }

    // then raw image data (e.g., svg)
    if (nft.image_data) {
      return (
        <div className="thumb">
          <img className="preview-file" src={imageDataURL} alt="preview" />
        </div>
      );
    }

    // then image file
    if (nft.image_url) {
      return (
        <div className="thumb">
          {/* Handle metadata where a video file is set for `image_url` (instead of the expected `animation_url`) */}
          {isHTMLVideoFormat(nft.image_url) ? (
            <video
              className="preview-file"
              controls
              autoPlay
              controlsList="nodownload"
              loop
              playsInline
              src={nft.image_url}
            />
          ) : (
            <img className="preview-file" src={nft.image_url} alt="preview" />
          )}
        </div>
      );
    }

    return <div className="no-image-available">No image available</div>;
  }

  function renderApplicantInfo(): React.ReactNode {
    if (!commonData) {
      return;
    }

    const connectedUserIsAdmin =
      ADMIN_ACCOUNTS &&
      account &&
      ADMIN_ACCOUNTS.split(',')
        .map((account) => normalizeString(account))
        .includes(normalizeString(account));

    if (connectedUserIsAdmin) {
      return (
        <div className="nft-proposaldetails__applicant-info">
          <div className="nft-preview__text">
            {commonData.msg.payload.metadata.emailAddress ? (
              <>
                <label>Contact:</label>{' '}
                <a
                  href={`mailto:${commonData.msg.payload.metadata.emailAddress}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {commonData.msg.payload.metadata.emailAddress}
                </a>
              </>
            ) : (
              'No contact email provided'
            )}
          </div>
        </div>
      );
    }
  }

  function renderNFTDetailsLink(): React.ReactNode {
    if (!nft) return;

    return (
      <a
        className="nft-preview__link"
        href={`${OPENSEA_ASSETS_URL}/${nft.address.toLowerCase()}/${
          nft.tokenId
        }`}
        target="_blank"
        rel="noopener noreferrer">
        <span>view details</span>
        <ExternalLinkSVG width="12px" height="12px" />
      </a>
    );
  }

  /**
   * Render
   */

  if (!commonData) {
    return null;
  }

  return (
    <>
      <div className="proposaldetails__header">Details</div>
      <div className="proposaldetails">
        <div className="nft-proposaldetails__content">
          {/* NFT CONTENT */}
          {renderNFT()}

          {/* PROPOSAL BODY */}
          <div className="nft-proposaldetails__body">
            <Markdown>{commonData.msg.payload.body}</Markdown>
          </div>
        </div>

        {/* SIDEBAR */}
        <div className="proposaldetails__status">
          {/* SPONSOR & VOTING ACTIONS */}
          {renderActions()}

          {/* APPLICANT INFO */}
          {renderApplicantInfo()}
        </div>
      </div>
    </>
  );
}
