import {useState, useEffect} from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import LazyLoad from 'react-lazyload';

import {FetchedNFT} from './types';
import {
  is3DModelFormat,
  isHTMLVideoFormat,
  isIframeSafeFormat,
} from './helpers';
import Loader from '../feedback/Loader';

type NFTCardProps = {
  showInModal?: boolean;
  selectNFTHandler?: (nft: FetchedNFT) => void;
  closeHandler?: () => void;
} & FetchedNFT;

const PLACEHOLDER = '\u2014'; /* em dash */

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

/**
 * Shows a preview of an NFT's details
 *
 * @param {NFTCardProps} props
 * @returns {JSX.Element}
 */
export default function NFTCard(props: NFTCardProps): JSX.Element {
  const {showInModal, selectNFTHandler, closeHandler, ...nft} = props;

  /**
   * State
   */

  const [imageDataURL, setImageDataURL] = useState<string>();

  /**
   * Variables
   */

  const {animation_url, image_data, image_url, tokenName, tokenURIName} = nft;

  /**
   * Effects
   */

  useEffect(() => {
    if (image_data) {
      const svg = image_data;
      const blob = new Blob([svg], {type: 'image/svg+xml'});
      setImageDataURL(URL.createObjectURL(blob));
    }

    return function cleanup() {
      // Make sure to revoke the data URIs to avoid memory leaks
      imageDataURL && URL.revokeObjectURL(imageDataURL);
    };
  }, [imageDataURL, image_data]);

  /**
   * Functions
   */

  function renderImage(): React.ReactNode {
    // detect video file first to display
    if (animation_url) {
      return (
        <div className="thumb">
          <LazyLoad
            once
            placeholder={<Loader />}
            height={20}
            offset={250}
            overflow={showInModal}>
            {isHTMLVideoFormat(animation_url) ? (
              <video
                className="preview-file"
                controlsList="nodownload"
                loop
                playsInline
                src={animation_url}
              />
            ) : // Use image in card if available instead of animation
            image_url ? (
              <img className="preview-file" src={image_url} alt="preview" />
            ) : is3DModelFormat(animation_url) ? (
              <model-viewer
                src={animation_url}
                auto-rotate
                autoplay
                camera-controls
                ar-status="not-presenting"></model-viewer>
            ) : isIframeSafeFormat(animation_url) ? (
              <iframe
                className="preview-file"
                src={animation_url}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                title="preview"
              />
            ) : (
              // fall back to placeholder text if src is not trusted format
              <div className="no-image-available">Media type not supported</div>
            )}
          </LazyLoad>
        </div>
      );
    }

    // then raw image data (e.g., svg)
    if (image_data) {
      return (
        <div className="thumb">
          <LazyLoad
            once
            placeholder={<Loader />}
            height={20}
            offset={250}
            overflow={showInModal}>
            <img className="preview-file" src={imageDataURL} alt="preview" />
          </LazyLoad>
        </div>
      );
    }

    // then image file
    if (image_url) {
      return (
        <div className="thumb">
          <LazyLoad
            once
            placeholder={<Loader />}
            height={20}
            offset={250}
            overflow={showInModal}>
            {/* Handle metadata where a video file is set for `image_url` (instead of the expected `animation_url`) */}
            {isHTMLVideoFormat(image_url) ? (
              <video
                className="preview-file"
                controlsList="nodownload"
                loop
                playsInline
                src={image_url}
              />
            ) : (
              <img className="preview-file" src={image_url} alt="preview" />
            )}
          </LazyLoad>
        </div>
      );
    }

    return <div className="no-image-available">No image available</div>;
  }

  function handleClick() {
    if (selectNFTHandler) {
      selectNFTHandler(nft);
      closeHandler && closeHandler();
    }
  }

  /**
   * Render
   */

  return (
    <div className="nft-card" onClick={handleClick}>
      {/* IMAGE */}
      <div className="nft-card__image-container">{renderImage()}</div>

      {/* TITLE */}
      <h3 className="nft-card__title">
        <ResponsiveEllipsis
          text={tokenURIName || PLACEHOLDER}
          maxLine={1}
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </h3>

      {/* SUBTITLE */}
      <div className="nft-card__subtitle">
        <ResponsiveEllipsis
          text={tokenName || PLACEHOLDER}
          maxLine={1}
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>
    </div>
  );
}
