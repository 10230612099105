import {Helmet} from 'react-helmet';

export default function Head() {
  return (
    <Helmet>
      <title>MUSE0</title>
      <meta
        name="description"
        content="An Internet museum created by an alien, curated by artists and collectors"
      />
    </Helmet>
  );
}
