import {DOCS_URL} from '../config';
import SocialMedia from './common/SocialMedia';

export default function Footer() {
  return (
    <div className="footer">
      <a href={DOCS_URL} target="_blank" rel="noopener noreferrer">
        Docs
      </a>
      <SocialMedia />
    </div>
  );
}
